import React, { useReducer, useContext, useEffect } from 'react'
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { Button, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import AuthContext from '../../context/auth-context';
import GQL from '../../GQL';
import moment from 'moment-timezone';

const initialValue = {
  isLoading: false,
  data: [],
  error: null,
  isReload:true,
  isProcess:false
}

const reducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...initialValue,
        isLoading: true
      }
    case "LOADED":
      return {
        ...state,
        isLoading: false,
        data: action.data,
        isReload:false
      }
    case "ERROR":
      return {
        ...state,
        isLoading: false,
        isReload:false,
        isProcess:false,
        error: action.error
      }
    case "CANCELING":
      return {
        ...state,
        isProcess:true
      }
    case "CANCELED":
      return {
        ...state,
        isProcess:false,
        isReload:true
      }
    default: return state;
  }
}

const getStatus = (status) => {
  switch (status) {
    case 1:
      return "Aktif"
    case 8:
      return "Batal"
    default: return "Invalid"
  }
}

const Komisi = (props) => {

  const [state, dispatch] = useReducer(reducer, initialValue)
  const { clientID, token } = useContext(AuthContext)

  useEffect(() => {
    var render = true
    if(state.isReload){
      dispatch({ type: "LOADING" })
      const query = `
        query{
          listKomisi {
            notrans
            tanggal
            status
            detail{
              urut
            }
          }
        }
      `
      GQL(query, clientID, token).then(res => {
        if (res.errors) throw new Error(res.errors);
        if(render) dispatch({ type: "LOADED", data: res.data.listKomisi })
      }).catch(err => {
        console.log(err);
        if(render) dispatch({ type: "ERROR", error: err })
      })
    }
    return () => {
      render = false
    };
  }, [state.isReload])

  const addKomisi = ()=>{
    props.history.push('/komisidetail')
  }

  const viewKomisi =(notrans)=>{
    props.history.push(`/komisidetail/${notrans}`)
  }

  const hapusKomisi = (notrans)=>{
    const yakin = window.confirm(`Yakin akan membatalkan pencairan komisi ${notrans}?`)
    if(yakin){
      dispatch({type:"CANCELING"});
      const query = `
        mutation{
          batalKomisi(notrans:"${notrans}")
        }
      `
      GQL(query,clientID,token).then(resp => {
        if(resp.errors) throw resp.errors;
        if(!resp.data.batalKomisi){
          alert("Mohon maaf. Server gagal membatalkan transaksi Anda.");
        }
        dispatch({type:"CANCELED"})
      }).catch(err => {
        console.log(err);
        alert("Tidak dapat memproses permintaan Anda saat ini");
        dispatch({type:"ERROR",error:err});
      })
    }
  }

  if (state.isLoading) return (<Spinner animation="border" role="status" className="spinner">
    <span className="sr-only">Loading...</span>
  </Spinner>)

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <h3 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom">Pencairan Komisi</h3>
          <Button variant="contained" color="primary" onClick={addKomisi}>Add New</Button>
          <Paper className="table-responsive" style={{ marginTop: 10 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Nomor Transaksi</TableCell>
                  <TableCell>Tanggal</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Jumlah Transaksi</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.data.length > 0 && state.data.map((el,index)=>(
                  <TableRow key={index.toString()}>
                    <TableCell>{index+1}</TableCell>
                    <TableCell>{el.notrans}</TableCell>
                    <TableCell>{moment(el.tanggal).format("DD MMM YYYY")}</TableCell>
                    <TableCell>{getStatus(el.status)}</TableCell>
                    <TableCell align="center">{el.detail.length}</TableCell>
                    <TableCell>
                      <Button variant="contained" disabled={state.isProcess} color="primary" onClick={viewKomisi.bind(this,el.notrans)} style={{marginRight:10}}>View Transaksi</Button>
                      <Button variant="contained" disabled={state.isProcess} style={{backgroundColor:'red'}} onClick={hapusKomisi.bind(this,el.notrans)}>Batal Transaksi</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Col>
      </Row>
    </Container>
  )
}

export default Komisi;