import React, { useState, useEffect } from 'react'
import { isArray } from 'util';
import { Row, Col } from 'react-bootstrap';
import { Button, Toolbar, Typography, IconButton, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import useStyles from '../../Desain/useStylesTabel'
import GoogleMapReact from 'google-map-react';
import Geolocation from 'react-geolocation'
import GQL from '../../GQL'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const ModalImportPortal = (props) => {
    const classes = useStyles();
    const [center, setCenter] = useState({ lat: 0, lng: 0 })
    const [form, setForm] = useState({ lat: 0, lng: 0 })


    const handleClose = () => {
        props.setOpen(false)
    }

    const getCurrentPosition = position => {
        setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude
        })
        setForm({
            lat: `${position.coords.latitude}`,
            lng: `${position.coords.longitude}`
        })
    };
    const pilihTitik = titik => {
        setCenter({
            lat: titik.lat,
            lng: titik.lng
        })

        setForm({
            lat: `${titik.lat}`,
            lng: `${titik.lng}`
        })
    }
    const handleSimpan = () => {
        const query = `
        mutation{
          updateOutlet(
            input:{
              kodeoutlet:"${props.kodeoutlet}",
              latitude:"${form.lat}",
              longitude:"${form.lng}"
            }
          )
        }
      `
        GQL(query, props.clientID, props.token).then(resp => {
            if (resp.errors) throw resp.errors;
            if (resp.data.updateOutlet) {
                alert("Lokasi Berhasil Disimpan");
                props.dispatch({ type: "SAVED" })
                handleClose()
                window.location.reload();

            }
        }).catch(err => {
            alert(err)
        })
    }
    const renderMarkers = (map, maps) => {
        new maps.Marker({
            position: center,
            map,
            title: 'Lokasi device anda'
        });
    }
    return (
        <Dialog fullScreen open={props.open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Pilih Lokasi
                    </Typography>
                </Toolbar>
            </AppBar>

            <div className="contentManual DialogFont bodyDialog">
                <Row style={{ marginTop: 20, marginBottom: 20 }}>
                    <Col xs={12} md={6}>
                        <TextField
                            id="latitude"
                            name="latitude"
                            label="Latitude"
                            fullWidth
                            required
                            value={form.lat}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <TextField
                            id="longitude"
                            name="Longitude"
                            label="Longitude"
                            fullWidth
                            required
                            value={form.lng}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button style={{ marginTop: '5px' }} variant="contained" color="primary" fullWidth onClick={handleSimpan}>Simpan Koordinat </Button>
                    </Col>
                </Row>
                <Row style={{ marginTop: 15 }}>
                    <Col>
                        <p className="pilihLokasi text-center">Pilih titik lokasi menggunakan <b> cursor mouse</b> anda, lalu klik <b> kiri pada mouse</b>. Lihat form <b>Latitude</b> dan<b> Longitude </b> berubah, setelah anda tekan <b>simpan kordinat</b> lokasi<b>  MAP</b>  anda di form luar akan tampil. </p>
                        <Geolocation
                            onSuccess={getCurrentPosition}
                        />
                        {(center.lat > 0 && center.lng > 0) && (
                            <div className='google-map' style={{ height: '70vh', width: '100%' }}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: process.env.REACT_APP_KEYMAP }}
                                    defaultCenter={center}
                                    defaultZoom={18}
                                    yesIWantToUseGoogleMapApiInternals
                                    onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                                    onClick={e => pilihTitik(e)}>
                                </GoogleMapReact>
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
        </Dialog>
    )
}

export default ModalImportPortal;

