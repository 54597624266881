import React from 'react'
import { Col } from 'react-bootstrap'
import { Card, CardContent } from '@material-ui/core'
import AnimateOnChange from 'react-animate-on-change'

const MyCard = (props) => {
  return (
    <Col xs={12} sm={6} md={4} lg={3} className="cardHolder">
      <Card className="cardTemplate">
        <CardContent className="text-right">
          <div className="cardIcon" style={{ backgroundColor: props.bgcolor }}>
            <props.icon style={{ fontSize: 64 }} />
          </div>
          <p className="cardTitle">{props.cardTitle}</p>
          <h3 className="cardValue">
            <AnimateOnChange
              baseClassName="cardValueBase"
              animationClassName="cardValueChanging"
              animate={true}
            >
              {props.cardValue}
            </AnimateOnChange>
          </h3>
        </CardContent>
      </Card>
    </Col>
  )
}

export default MyCard;