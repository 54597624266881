import React, { useEffect, useReducer, useContext, useState } from 'react'
// import { RecyclerListView, DataProvider, LayoutProvider } from 'recyclerlistview/web';
import { Spinner, Container, Row, Col } from 'react-bootstrap';
import AuthContext from '../../context/auth-context';
import GQL from '../../GQL';
import { Button, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Paper, InputBase } from '@material-ui/core';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import Dropdown from 'react-bootstrap/Dropdown'
import Modal from '@material-ui/core/Modal';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SearchIcon from '@material-ui/icons/Search'
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import './Outlet.css'
import { Nav } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import useStyles from '../../Desain/useStylesTabel'
import Skeleton from 'react-loading-skeleton';
import BlockIcon from '@material-ui/icons/Block';
import ExportExel from './Outlet.Export'


const loadingRow = [0, 1, 2, 3, 4];
const initialValue = {
    isLoading: false,
    dataSource: [],
    error: null,
    noData: false,
    isReload: true
}

const reducer = (state, action) => {
    switch (action.type) {
        case "LOADING":
            return {
                ...initialValue,
                isLoading: true
            }
        case "LOADED":
            return {
                ...state,
                isLoading: false,
                dataSource: action.data,
                isReload: false
            }
        case "FAILED":
            return {
                ...state,
                isLoading: false,
                error: action.error,
                isReload: false
            }
        case "NODATA":
            return {
                ...state,
                isLoading: false,
                noData: true
            }
        case "RELOAD":
            return {
                ...state,
                isReload: true
            }
        default: return state;
    }
}

const desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

const getSorting = (order, orderBy) => {
    return order === "desc"
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}
const headRows = [
    { id: "namaoutlet", numeric: true, disablePadding: false, label: "Nama Outlet" },
    { id: "expiryDate", numeric: true, disablePadding: false, label: "Expiry Date" },
    { id: "isOpname", numeric: true, disablePadding: false, label: "Opname" },

];

const Outlet = (props) => {
    let defaultPage = parseInt(props.match.params.page, 10) - 1;
    let defaultrowsPerPage = parseInt(props.match.params.rowsPerPage, 10);
    const { clientID, token } = useContext(AuthContext);
    const [state, dispatch] = useReducer(reducer, initialValue);
    const [open, setOpen] = useState(false);
    const [selectedToken, setSelectedToken] = useState({ url: null, token: null })
    const classes = useStyles();
    const [order, setOrder] = React.useState("desc");
    const [orderBy, setOrderBy] = React.useState("idcust");
    const [page, setPage] = React.useState(defaultPage);
    const [rowsPerPage, setRowsPerPage] = React.useState(defaultrowsPerPage);
    const [search, setSearch] = useState(null)
    const [dataOri, setDataOri] = useState([])
    const [isLoading, setIsLoading] = useState(false)


    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        var render = true;
        if (state.isReload) {
            render && dispatch({ type: "LOADING" });
            const query = `
          query{
            listOutletV2{
              kodeoutlet
              namaoutlet
              isOpname
              email
              expiryDate
            }
          }
        `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) throw resp.errors;
                render && dispatch({ type: "LOADED", data: resp.data.listOutletV2 });
                setDataOri(resp.data.listOutletV2)
            }).catch(err => {
                if (Array.isArray(err)) {
                    if (err.length > 0) {
                        if (err[0].message === "Data Tidak Ditemukan") return (dispatch({ type: "NODATA" }));
                    }
                }
                dispatch({ type: "FAILED", error: err });
            })
        }
        return () => {
            render = false;
        };
    }, [state.isReload, clientID, token])

    const addOutlet = () => {
        props.history.push('/addOutlet');
    }

    const handleEdit = (data) => {
        props.history.push(`/outlet/${data.kodeoutlet}/${page + 1}/${rowsPerPage}`)
    }

    const handleDelete = ({ kodeoutlet, namaoutlet }) => {
        const konfirm = window.confirm(`Yakin akan non-aktifkan outlet ${namaoutlet}?`);
        if (konfirm) {
            const query = `
                mutation{
                    nonAktifOutlet(kodeoutlet:"${kodeoutlet}")
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) throw resp.errors;
                if (resp.data.nonAktifOutlet) {
                    alert("Outlet berhasil di non-aktifkan")
                    dispatch({ type: 'RELOAD' })
                }
            }).catch(err => {
                if (Array.isArray(err)) {
                    if (err.length > 0) {
                        if (err[0].message === "Data Tidak Ditemukan") return (dispatch({ type: "NODATA" }));
                    }
                }
                dispatch({ type: "FAILED", error: err });
            })
        }
    }

    const handleToken = (data) => {
        setOpen(true);
        setIsLoading(true)
        const query = `
                    query{
                        getToken(kodeoutlet:"${data.kodeoutlet}"){
                            token
                            url
                            id
                            urut
                        }
                    }
                `
        GQL(query, clientID, token).then(resp => {
            if (resp.errors) throw resp.errors;
            setIsLoading(false)
            setSelectedToken(resp.data.getToken);
        }).catch(err => {
            if (Array.isArray(err)) {
                if (err.length > 0) {
                    alert("Token tidak ditemukan")
                }
            }
        })
    }
    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }
    const EnhancedTableHead = (props) => {
        const {
            classes,
            order,
            orderBy,
            onRequestSort
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };
        return (
            <TableHead>
                <TableRow>
                    {headRows.map(row => (
                        <TableCell key={row.id}>
                            <TableSortLabel
                                active={orderBy === row.id}
                                direction={order}
                                onClick={createSortHandler(row.id)}
                            >
                                {row.label}
                                {orderBy === row.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                    <TableCell style={{ width: ' 25px' }}>Action</TableCell>
                </TableRow>
            </TableHead>
        );
    }
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, state.dataSource.length - page * rowsPerPage);
    const searchHandler = (val) => {
        setSearch(val.target.value)
    }
    const formSubmitHandler = () => {
        setPage(0)
        setRowsPerPage(5)
        if (search) {
            const results = dataOri.filter(el => {
                return el.namaoutlet.toUpperCase().match(search.toUpperCase()) || el.email.toUpperCase() === search.toUpperCase();
            })
            dispatch({ type: "LOADED", data: results });
        } else {
            dispatch({ type: "LOADED", data: dataOri });
        }
    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            formSubmitHandler()
        }
    }
    if (state.noData) return (
        <p>No Data Found</p>
    )
    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <h4 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom">List Outlet</h4>
                    <Button variant="contained" color="primary" onClick={addOutlet}>Add New</Button>
                    <Paper className="table-responsive" style={{ marginTop: 10 }}>
                        <Nav variant="tabs">
                            <Nav.Item>
                                <NavLink className="nav-link tabOutlet tabOutletActive" to="/outlet/1/5">Sudah berjalan</NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <NavLink className="nav-link tabOutlet" to="/outletIsTes/1/5">Outlet tes</NavLink>
                            </Nav.Item>
                        </Nav>
                        <div className="btnTable">
                            <Row>
                                <Col xs={12} md={4}>
                                    <ExportExel data={state.dataSource} isLoading={state.isLoading} name="Outlet"/>
                                </Col>
                                <Col xs={12} md={8}>
                                    <div className={classes.search}>
                                        <div className={classes.searchIcon}>
                                            <SearchIcon />
                                        </div>
                                        <InputBase onChange={searchHandler} onKeyPress={handleKeyPress}
                                            placeholder="Search…"
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputInput,
                                            }}
                                            inputProps={{ 'aria-label': 'search' }}
                                        />
                                        <Button style={{ float: 'right' }} onClick={formSubmitHandler} variant="contained" color="primary">Cari</Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className={classes.tableWrapper}>
                            <Table
                                className="table"
                                aria-labelledby="tableTitle"
                                size={"small"}
                            >
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={state.dataSource.length}
                                />
                                {state.isLoading && loadingRow.map(el => (
                                    <TableRow
                                        key={el.toString()}
                                        style={{ height: 50 }}
                                    >
                                        <TableCell>
                                            <Skeleton />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton />
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {stableSort(state.dataSource, getSorting(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                key={index}
                                                role="checkbox"
                                                tabIndex={-1}
                                            >
                                                <TableCell>
                                                    {row.namaoutlet}
                                                </TableCell>
                                                <TableCell>
                                                    {row.expiryDate}
                                                </TableCell>
                                                <TableCell component="th" scope="row" >
                                                    {row.isOpname ? <CheckIcon /> : <CloseIcon />}
                                                </TableCell>
                                                <TableCell>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                            <TouchAppIcon id="dropdown-basic" /></Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item><Button title="Edit" variant="contained" style={{ backgroundColor: '#376275', marginRight: 10, color: "#fff", width: '135px' }} onClick={handleEdit.bind(this, row)}><EditIcon /> Edit/lihat</Button></Dropdown.Item>
                                                            <Dropdown.Item><Button title="Hapus" variant="contained" style={{ backgroundColor: 'red', marginRight: 10, color: "#fff", width: '135px' }} onClick={handleDelete.bind(this, row)}><BlockIcon /> Non-Aktif</Button></Dropdown.Item>
                                                            <Dropdown.Item><Button title="Hapus" variant="contained" style={{ backgroundColor: '#5bc0de', marginRight: 10, color: "#fff", width: '135px' }} onClick={handleToken.bind(this, row)}><VisibilityIcon /> View Token</Button></Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    {/* <Button variant="contained" style={{ backgroundColor: 'gray', marginRight: 10 }} onClick={handleEdit.bind(this, row.kodeoutlet)}>Edit</Button>
                                                    <Button variant="contained" style={{ backgroundColor: 'red', marginRight: 10 }} onClick={handleDelete.bind(this, row)}>Hapus</Button>
                                                    <Button variant="contained" style={{ backgroundColor: '#5bc0de', marginRight: 10 }} onClick={handleToken.bind(this, row)}>View Token</Button> */}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 49 * emptyRows }}>
                                        <TableCell colSpan={7} />
                                    </TableRow>
                                )}
                                <TableBody>

                                </TableBody>
                            </Table>
                        </div>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            component="div"
                            count={state.dataSource.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                "aria-label": "previous page"
                            }}
                            nextIconButtonProps={{
                                "aria-label": "next page"
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Col>
            </Row>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Paper style={{ top: `calc(50% - ${362 / 2}px)`, left: `calc(50% - 160px)`, width: '320px', position: 'absolute', border: '2px solid #313131', outline: 'none' }}>
                    {isLoading ? (
                        <React.Fragment>
                            <Skeleton height={250} />
                            <Skeleton />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <img src={selectedToken.url} className="img-responsive img-thumbnail" alt="token" />
                            <p style={{ padding: 10 }} className='text-center' >Token: <br /><strong style={{ fontSize: 12, wordWrap: 'break-word' }}>{selectedToken.token}</strong></p>
                            <p className='text-center' >Kode Outlet Delivery: <br /><strong style={{ fontSize: 12, wordWrap: 'break-word' }}>{selectedToken.urut}</strong></p>
                        </React.Fragment>
                    )}
                </Paper>

            </Modal>
        </Container>
    )
}

export default Outlet;