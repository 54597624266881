import React, { useEffect, useReducer, useContext, useState } from 'react'
// import { RecyclerListView, DataProvider, LayoutProvider } from 'recyclerlistview/web';
import { Spinner, Container, Row, Col } from 'react-bootstrap';
import AuthContext from '../../context/auth-context';
import GQL from '../../GQL';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import './Customer.css'
import { Button } from '@material-ui/core';
import { Nav } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
const initialValue = {
    isLoading: false,
    dataSource: [],
    error: null,
    noData: false,
    isReload: true
}

const reducer = (state, action) => {
    switch (action.type) {
        case "LOADING":
            return {
                ...initialValue,
                isLoading: true
            }
        case "LOADED":
            return {
                ...state,
                isLoading: false,
                dataSource: action.data,
                isReload: false
            }
        case "FAILED":
            return {
                ...state,
                isLoading: false,
                error: action.error,
                isReload: false
            }
        case "NODATA":
            return {
                ...state,
                isLoading: false,
                noData: true
            }
        case "RELOAD":
            return {
                ...state,
                isReload: true
            }
        default: return state;
    }
}

const Outlet = (props) => {

    const { clientID, token } = useContext(AuthContext);
    const [state, dispatch] = useReducer(reducer, initialValue);
    const [open, setOpen] = useState(false);
    const [selectedToken, setSelectedToken] = useState({ url: null, token: null })

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        var render = true;
        if (state.isReload) {
            render && dispatch({ type: "LOADING" });
            const query = `
                            query{
                                listCustomer(aktivasi:false){
                                    email
                                    namacustomer
                                    alamat
                                    salt
                                    branchcode
                                }
                            }
                        `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) throw resp.errors;
                render && dispatch({ type: "LOADED", data: resp.data.listCustomer });
            }).catch(err => {
                if (Array.isArray(err)) {
                    if (err.length > 0) {
                        if (err[0].message === "Data Tidak Ditemukan") return (dispatch({ type: "NODATA" }));
                    }
                }
                dispatch({ type: "FAILED", error: err });
            })
        }
        return () => {
            render = false;
        };
    }, [state.isReload, clientID, token])
    const handleAktivasi = (data) => {
        var r = window.confirm(`Yakin aktivasi user ${data.email} ini?`);
        if (r) {
            const query = `
            mutation{
                aktivasiCustomer(kode:"${data.salt}")
            }
        `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) throw resp.errors;
                if (resp) {
                    props.history.push('/customer')
                    // dispatch({ type: "RELOAD" });
                }
            }).catch(err => {
                if (Array.isArray(err)) {
                    if (err.length > 0) {
                        alert(err[0].message)
                    }
                }
            })
        }
    }

    if (state.isLoading) return (<Spinner animation="border" role="status" className="spinner">
        <span className="sr-only">Loading...</span>
    </Spinner>)


    if (state.noData) return (
        <p>No Data Found</p>
    )
    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <h3 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom">List User Customer Portal</h3>
                    <Paper className="table-responsive" style={{ marginTop: 10 }}>
                        <Nav variant="tabs">
                            <Nav.Item>
                                <NavLink className="nav-link tabOutlet tabOutletActive" to="/customer">Aktivasi</NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <NavLink className="nav-link tabOutlet " to="/customer-nonaktivasi">Non-Aktivasi</NavLink>
                            </Nav.Item>
                        </Nav>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Nama</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    state.dataSource.map((el, idx) => {
                                        return (
                                            <TableRow key={el.kodeoutlet}>
                                                <TableCell>{idx + 1}</TableCell>
                                                <TableCell>{el.email}</TableCell>
                                                <TableCell>{el.namacustomer}</TableCell>
                                                <TableCell>
                                                    <Button variant="contained" style={{ backgroundColor: '#5bc0de', color: '#fff', marginRight: 10 }} onClick={handleAktivasi.bind(this, el)}>Aktivasi</Button>
                                                    {/* <Button variant="contained" style={{ backgroundColor: 'red', marginRight: 10 }} onClick={handleDelete.bind(this, el)}>Hapus</Button>
                                                    <Button variant="contained" style={{ backgroundColor: '#5bc0de', marginRight: 10 }} onClick={handleToken.bind(this, el)}>View Token</Button> */}
                                                </TableCell>
                                            </TableRow>

                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Paper>
                </Col>
            </Row>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Paper style={{ top: `calc(50% - ${362 / 2}px)`, left: `calc(50% - 160px)`, width: '320px', position: 'absolute', border: '2px solid #313131', outline: 'none' }}>
                    <img src={selectedToken.url} className="img-responsive img-thumbnail" alt="token" />
                    <p style={{ padding: 10 }} className='text-center' >Token: <br /><strong style={{ fontSize: 12, wordWrap: 'break-word' }}>{selectedToken.token}</strong></p>
                </Paper>

            </Modal>
        </Container>
    )
}

export default Outlet;