/* eslint-disable no-unreachable */
import React, { useEffect, useState, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import GQL from '../../GQL';
import AuthContext from '../../context/auth-context'
import { FaChevronLeft } from 'react-icons/fa';
import DateFnsUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment-timezone'
import { TextField, Button, FormControl, MenuItem, InputLabel, Select } from '@material-ui/core';
const Contract = (props) => {

    const { clientID, token } = useContext(AuthContext)
    const [namaoutlet, setNamaoutlet] = useState(null)
    const { kodeoutlet } = props.match.params;
    const [tglPayment, setTglPayment] = useState(moment())
    const [tglExpiry, setTglExpiry] = useState(moment())
    const [PaymentAmount, setPaymentAmount] = useState(0)
    const [deskripsi, setDeskripsi] = useState(null)
    const [payment, setPayment] = useState()
    const [listPayment, setListPayment] = useState([])

    useEffect(() => {
        var render = true;
        if (render && kodeoutlet) {
            const query = `
                        query{
                            outlet(kodeoutlet:"${kodeoutlet}"){
                            namaoutlet
                            }
                        }
                        `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) throw resp.errors;
                setNamaoutlet(resp.data.outlet.namaoutlet);
            }).catch(err => {
                console.log(err);
                alert("Server tidak dapat memenuhi permintaan Anda saat ini. Harap coba beberapa saat lagi")
            })

            fetch(`${process.env.REACT_APP_URL}/payment/get`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-CLIENT-ID': clientID,
                    'X-API-TOKEN': token
                },
            }).then(res => res.json())
                .then(
                    (result) => {
                        setListPayment(result.data)
                    },
                    (error) => {
                        console.log(error)
                    }
                );

        }
        return () => {
            render = false;
        };
    }, [kodeoutlet])

    const goBack = () => {
        props.history.push(`/outlet/${kodeoutlet}/1/5`)
    }

    const handleDateChangePayment = (date) => {
        setTglPayment(date)
    }
    const handleDateChangeExpiry = (date) => {
        setTglExpiry(date)
    }

    const handlePayAmount = (event) => {
        setPaymentAmount(+event.target.value)
    }
    const handleDeskripsi = (event) => {
        setDeskripsi(event.target.value)
    }
    const handleChangePayment = (event) => {
        setPayment(event.target.value)
    }

    const simpanDetail = () => {
        console.log(deskripsi)
        var yakin = window.confirm(`Yakin akan menyimpan data ini?`);
        if (yakin) {
            fetch(`${process.env.REACT_APP_URL}/outlet/simpanDetail`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-CLIENT-ID': clientID,
                    'X-API-TOKEN': token
                },
                body: JSON.stringify({
                    "kodeOutlet": kodeoutlet,
                    "idPayment":payment,
                    "paymentDeskripsi":deskripsi,
                    "paymentDate":new Date(tglPayment),
                    "paymentAmount":parseFloat(PaymentAmount),
                    "expiryDate":new Date(tglExpiry)
                })
            }).then(res => res.json())
                .then(
                    (result) => {
                        alert("Data berhasil disimpan");
                        props.history.push(`/outlet/${kodeoutlet}`)
                    },
                    (error) => {
                        console.log(error)
                    }
                );
        }
    }

    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <h3 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={goBack.bind(this)} className="btnBack" /> Outlet Detail {namaoutlet}</h3>
                    <FormControl fullWidth={true}>
                        <InputLabel>Payment</InputLabel>
                        <Select
                            name="Payment"
                            value={payment}
                            onChange={handleChangePayment}
                        >
                            <MenuItem value={''}>Pilih Payment</MenuItem>
                            {listPayment.map(el => (
                                <MenuItem key={el.id} value={el.id}>{el.namapayment}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        id="deskripsi"
                        label="Deskripsi"
                        value={deskripsi}
                        fullWidth={true}
                        onChange={handleDeskripsi}
                        style={{ marginTop: 20, marginBottom: 20 }}
                        type="text"
                    />
                    <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                        <KeyboardDatePicker
                            fullWidth={true}
                            margin="normal"
                            id="paymentDate"
                            label="payment Date"
                            format="DD MMM YYYY"
                            value={tglPayment}
                            onChange={handleDateChangePayment}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            required
                        />
                    </MuiPickersUtilsProvider>
                    <TextField
                        id="PaymentAmount"
                        type="number"
                        label="Payment Amount"
                        value={PaymentAmount}
                        fullWidth={true}
                        required
                        onChange={handlePayAmount}
                        style={{ marginTop: 20, marginBottom: 20 }}
                    />
                    <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                        <KeyboardDatePicker
                            fullWidth={true}
                            margin="normal"
                            id="expiryDate"
                            label="Expiry Date"
                            format="DD MMM YYYY"
                            value={tglExpiry}
                            onChange={handleDateChangeExpiry}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            required
                        />
                    </MuiPickersUtilsProvider>
                    <div className="text-center">
                        <Button variant="contained" color="primary" style={{ marginTop: 20, marginBottom: 20 }} onClick={simpanDetail}>Simpan</Button>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
export default Contract;