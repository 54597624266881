import GQL from '../../GQL';
const UserLaporan = {
    loadUserLaporan: (clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    listUserLaporan{
                        email
                        namacustomer
                        alamat
                        nohp
                        gender
                        salt
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.listUserLaporan) return (resolve(resp.data.listUserLaporan));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    savaUserLaporan: (clientID, token, email, namacustomer, nohp, alamat, gender, password) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    createUserLaporan(input:{
                        email:"${email}",
                        namacustomer:"${namacustomer}",
                        password:"${password}",
                        nohp:"${nohp}",
                        alamat:"${alamat}",
                        gender:${gender}
                    })
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.createUserLaporan) return (resolve(resp.data.createUserLaporan));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    updateUserLaporan: (clientID, token, email, namacustomer, nohp, alamat, gender) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    updateUserLaporan(input:{
                        email:"${email}",
                        namacustomer:"${namacustomer}",
                        nohp:"${nohp}",
                        alamat:"${alamat}",
                        gender:${gender}
                    })
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.updateUserLaporan) return (resolve(resp.data.updateUserLaporan));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    hapusUserCustomer: (clientID, token, email) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    hapusUserCustomer( email:"${email}")
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.hapusUserCustomer) return (resolve(resp.data.hapusUserCustomer));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    resetPassword: (clientID, token, email, password, salt) => {
        return new Promise((resolve, reject) => {
            const query = `
                    mutation{
                        resetPasswordCustomer(salt:"${salt}",email:"${email}", password:"${password}")
                    }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.resetPasswordCustomer) return (resolve(resp.data.resetPasswordCustomer));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
}

export default UserLaporan;