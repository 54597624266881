import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import validate from 'validate.js'
import mControl from './UserLaporan.ctrl'


const Modal = (props) => {
    const dataSelect = props.dataSelect
    const [isUpdate, setIsUpdate] = useState(false)
    const [password, setPassword] = useState('initialState')

    const handleClose = () => {
        props.setOpen(false);
    };


    const simpan = () => {
        if (password < 5) return alert("Password minimal 5 digit")
        mControl.resetPassword(props.clientID, props.token, dataSelect.email, password, dataSelect.salt).then(resp => {
            alert("Berhasil reset password")
            handleClose()
        }).catch(err => {
            if (err.length > 0) {
                alert(err[0].message)
            } else if (typeof err) {
                alert(err.message)
            } else {
                console.log(err);
            }
        })
    }


    return (
        <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Reset Passwors</DialogTitle>
            <DialogContent>
                <TextField
                    disabled
                    margin="dense"
                    id="email"
                    label="E-mail"
                    value={dataSelect.email}
                    fullWidth
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="New Password"
                    fullWidth
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">Cancel</Button>
                <Button onClick={simpan} color="secondary">Reset</Button>
            </DialogActions>
        </Dialog>
    )
}
export default Modal;
