import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import mControl from './UserLaporanOutlet.ctrl'
import Autocomplete from '@material-ui/lab/Autocomplete';


const Modal = (props) => {
    const listOutlet = props.listOutlet
    const [outlet, setOutlet] = useState(null)

    const handleClose = () => {
        props.setOpen(false);
    };

    console.log(outlet)

    const simpan = () => {
        if (outlet === null) return alert("Pilih oulet terlebih dahulu")
        mControl.savaUserOutlet(props.clientID, props.token, props.email,outlet.kodeoutlet).then(resp => {
            alert("Outlet Berhasil Ditambahkan")
            handleClose()
            props.setIsLoading(true)
        }).catch(err => {
            if (err.length > 0) {
                alert(err[0].message)
            } else if (typeof err) {
                alert(err.message)
            } else {
                console.log(err);
            }
        })
    }


    return (
        <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add Akses Outlet</DialogTitle>
            <DialogContent>
                <TextField
                    required
                    autoFocus
                    margin="dense"
                    id="email"
                    label="Email"
                    fullWidth
                    multiline={true}
                    value={props.email}
                    disabled
                    variant="outlined"
                />
                <Autocomplete
                    style={{ marginTop: 15 }}
                    id="combo-box-demo"
                    options={listOutlet}
                    getOptionLabel={(option) => option.namaoutlet}
                    fullWidth
                    value={outlet}
                    onChange={(event, values) => setOutlet(values)}
                    renderInput={(params) => <TextField {...params} label="Pilih Outlet" variant="outlined" />}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">Cancel</Button>
                <Button onClick={simpan} color="secondary">Simpan</Button>
            </DialogActions>
        </Dialog>
    )
}
export default Modal;
