import React, { useEffect, useReducer } from 'react';
// import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import AuthContext from './context/auth-context';
import { useCookies } from 'react-cookie';
import Login from './Components/Login/Login';
import Home from './Components/Home/Home';
import MyNavbar from './Components/MyNavbar/MyNavbar';
import Outlet from './Components/Outlet/Outlet';
import OutletIsTes from './Components/Outlet/OutletIsTes';
import AddOutlet from './Components/OutletDetail/AddOutlet';
import EditOutlet from './Components/OutletDetail/EditOutlet';
import Contract from './Components/OutletDetail/Contract';
import OutletDetail from './Components/OutletDetail/OutletDetail';
import Payment from './Components/Payment/Payment'
import PaymentDetail from './Components/Payment/PaymentDetail'
import User from './Components/User/User'
import UserDetail from './Components/User/UserDetail'
import Komisi from './Components/Komisi/Komisi'
import KomisiDetail from './Components/Komisi/KomisiDetail'
import Sales from './Components/Sales/Sales'
import SalesDetail from './Components/Sales/SalesDetail'
import CustomerAktivasi from './Components/Customer/CustomerAktivasi'
import CustomerNonAktivasi from './Components/Customer/CustomerNonAktivasi'
import UserLaporan from './Components/UserLaporan/UserLaporan'
import UserLaporanOutlet from './Components/UserLaporanOutlet/UserLaporanOutlet'
import Voucher from './Components/Voucher/Voucher'
import VoucherUsage from './Components/Voucher/VoucherUsage'

const initialValue = {
  token: null,
  clientID: null,
  isCookieLoad: false,
  isLogin: false
}

const loginReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        token: action.token,
        clientID: action.clientID,
        isLogin: true
      }
    case "LOGOUT":
      return {
        ...state,
        token: null,
        clientID: null
      }
    case "COOKIE":
      return {
        ...state,
        token: action.token,
        clientID: action.clientID,
        isCookieLoad: true
      }
    default: throw new Error("Invalid Action");
  }
}

function App() {
  const [state, dispatch] = useReducer(loginReducer, initialValue)
  const [cookies, setCookie, removeCookie] = useCookies([`${process.env.REACT_APP_COMPANY}-admin`]);
  useEffect(() => {
    var render = true;
    if (cookies[`${process.env.REACT_APP_COMPANY}-admin-auth`]) {
      const { clientID, token } = cookies[`${process.env.REACT_APP_COMPANY}-admin-auth`] || { clientID: null, token: null };
      if (render) {
        dispatch({ type: "COOKIE", clientID, token })
      }
    } else {
      dispatch({ type: "COOKIE", clientID, token })

    }
    return () => {
      render = false;
    };
  }, [cookies[`${process.env.REACT_APP_COMPANY}-admin-auth`]]);

  const login = (username, password) => {
    const myHeader = new Headers();
    myHeader.append("Accept", "application/json");
    myHeader.append("Content-Type", "application/json");

    const myInit = {
      method: "POST",
      headers: myHeader,
      body: JSON.stringify({
        username, password
      })
    }
    const myRequest = new Request(`${process.env.REACT_APP_URL}/users/authenticate`, myInit)
    fetch(myRequest).then(res => res.json()).then(res => {
      const { success, clientID, token, info } = res;
      if (success) {
        setCookie(`${process.env.REACT_APP_COMPANY}-admin-auth`, { clientID, token }, { path: '/' })
        dispatch({ type: "LOGIN", clientID, token })
      } else {
        throw new Error(info);
      }
    }).catch(err => {
      alert("Server tidak dapat memenuhi permintaan Anda saat ini. Harap coba kembali beberapa saat lagi");
      console.log(err);
    })
  }

  const logout = () => {
    dispatch({ type: "LOGOUT" })
    removeCookie(`${process.env.REACT_APP_COMPANY}-admin-auth`)
  }

  const { clientID, token, isCookieLoad, isLogin } = state;

  return (
    <Router>
      <React.Fragment>
        <AuthContext.Provider
          value={{
            token,
            clientID,
            login,
            logout
          }}
        >
          <MyNavbar onLogout={logout} isLogin={(token && clientID)} />
          <Switch>
            {(clientID && token && isCookieLoad) &&
              <React.Fragment>
                <Route path="/" exact component={Home} />
                <Route path="/outlet/:page/:rowsPerPage" component={Outlet} exact />
                <Route path="/outletIsTes/:page/:rowsPerPage" component={OutletIsTes} exact />
                <Route path="/addOutlet" component={AddOutlet} />
                <Route path="/outlet/:kodeoutlet/:page/:rowsPerPage" component={EditOutlet} />
                <Route path="/outletcontract/:kodeoutlet" component={Contract} />
                <Route path="/outletdetail/:kodeoutlet" component={OutletDetail} />
                <Route path="/payment" component={Payment} exact />
                <Route path="/paymentdetail" component={PaymentDetail} exact />
                <Route path="/paymentdetail/:id" component={PaymentDetail} />
                <Route path="/user/" component={User} exact />
                <Route path="/userdetail" component={UserDetail} exact />
                <Route path="/userdetail/:username" component={UserDetail} exact />
                <Route path="/komisi" component={Komisi} exact />
                <Route path="/komisidetail" component={KomisiDetail} exact />
                <Route path="/komisidetail/:notrans" component={KomisiDetail} exact />
                <Route path="/sales" component={Sales} exact />
                <Route path="/salesdetail" component={SalesDetail} exact />
                <Route path="/salesdetail/:email" component={SalesDetail} exact />
                <Route path="/customer/" component={CustomerAktivasi} exact />
                <Route path="/customer-nonaktivasi/" component={CustomerNonAktivasi} exact />
                <Route path="/userlaporan/:page/:rowsPerPage" component={UserLaporan} exact />
                <Route path="/userlaporan/:email/:page/:rowsPerPage" component={UserLaporanOutlet} exact />
                <Route path="/voucher/:page/:rowsPerPage" component={Voucher} exact />
                <Route path="/voucher-useage/:page/:rowsPerPage" component={VoucherUsage} exact />
                {isLogin && <Redirect from="/login/" to="/" exact />}
              </React.Fragment>
            }
            {((!clientID || !token) && isCookieLoad) &&
              <React.Fragment>
                <Route path="/login/" component={Login} />
                <Redirect to="/login/" />
              </React.Fragment>
            }
          </Switch>
        </AuthContext.Provider>
      </React.Fragment>

    </Router>
  );
}

export default App;
