import React, { useEffect, useContext, useReducer } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { FaStore, FaUsers, FaDollarSign } from 'react-icons/fa'
import MyCard from './MyCard'
import './Home.css'
import AuthContext from '../../context/auth-context'
import moment from 'moment-timezone'
import GQL from '../../GQL';
import NumberFormat from 'react-number-format';
const ActionType = {
  LOADING: 1,
  LOADED: 2,
  ERROR: 3
}

if (Object.freeze) Object.freeze(ActionType);

const initialValue = {
  isLoading: false,
  data: {
    jumlahOutlet: 0,
    outletAktif: 0,
    outletTrial:0,
    jumlahSales: 0,
    komisiCair: 0
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case ActionType.LOADING:
      return {
        ...state,
        isLoading: true,
        data: initialValue.data
      }
    case ActionType.LOADED:
      return {
        ...state,
        isLoading: false,
        data: action.data
      }
    case ActionType.ERROR:
      return {
        ...state,
        isLoading: false
      }
    default: return state;
  }
}

const Home = (props) => {
  const { clientID, token } = useContext(AuthContext)
  const [state, dispatch] = useReducer(reducer, initialValue)

  useEffect(() => {
    var render = true
    if (render) dispatch({ type: ActionType.LOADING })
    const query = `
      query {
        listOutlet{
          isTrialMode
          detail{
            expiryDate
          } 
        }
        listSales(activeOnly:true){
          email
        }
        listKomisi(periode:"${moment().format("YYYY-MM")}"){
          detail{
            id_trans{
              nilaikomisi
            }
          }
        }
      }
    `
    GQL(query, clientID, token).then(resp => {
      if (resp.errors) throw resp.errors;
      const jumlahOutlet = resp.data.listOutlet.length;
      const outletAktif = resp.data.listOutlet.map(element => {
        if(element.detail.filter( el  => {
          return moment(el.expiryDate).isSameOrAfter(moment())
        }).length > 0) return 1;
        return 0;
      }).reduce((a,b)=>a+b,0);
      const outletTrial = resp.data.listOutlet.filter(el => el.isTrialMode).map(element => {
        if (element.detail.filter(el => {
          return moment(el.expiryDate).isSameOrAfter(moment())
        }).length > 0) return 1;
        return 0;
      }).reduce((a, b) => a + b, 0);
      const jumlahSales = resp.data.listSales.length;
      const komisiCair = resp.data.listKomisi.map(element => {
        return element.detail.map(el => {
          return el.id_trans.nilaikomisi;
        }).reduce((a,b)=>a+b,0)
      }).reduce((a,b)=>a+b,0);
      if (render) dispatch({
        type: ActionType.LOADED, data: {
          jumlahOutlet,
          outletAktif,
          jumlahSales,
          komisiCair,
          outletTrial
        }
      })
    }).catch(err => {
      console.log(err);
      if (render) alert("Server tidak dapat memenuhi permintaan Anda saat ini. Harap coba beberapa saat lagi")
      if (render) dispatch({ type: ActionType.ERROR })
    })
    return () => {
      render = false
    };
  }, [clientID,token])

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <h3 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom">Dashboard</h3>
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <MyCard cardTitle="Outlet" icon={FaStore} cardValue={`${state.data.outletAktif} / ${state.data.jumlahOutlet}`} bgcolor={'rgba(255,191,97,1)'} />
        <MyCard cardTitle="Trial" icon={FaStore} cardValue={`${state.data.outletTrial} / ${state.data.outletAktif}`} bgcolor={'#C3C3E6'} />
        <MyCard cardTitle="Sales" icon={FaUsers} cardValue={state.data.jumlahSales} bgcolor={'#D1C8E1'} />
        <MyCard cardTitle="Komisi" icon={FaDollarSign} cardValue={<NumberFormat value={state.data.komisiCair} displayType={"text"} thousandSeparator={true} />} bgcolor={'#E5D1D0'} />
      </Row>
    </Container>
  )
}

export default Home;