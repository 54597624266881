import React, { useReducer, useEffect, useContext } from 'react'
import { Container, Button, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { Row, Col, Spinner } from 'react-bootstrap';
import GQL from '../../GQL';
import AuthContext from '../../context/auth-context'


const initialValue = {
  isLoading: false,
  data: [],
  error: null,
  isReload: true
}

const reducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...initialValue,
        isLoading: true
      }
    case "LOADED":
      return {
        ...state,
        isLoading: false,
        data: action.data,
        isReload: false
      }
    case "ERROR":
      return {
        ...state,
        isLoading: false,
        error: action.error,
        isReload: false
      }
    case "DELETED":
      return {
        ...state,
        isReload: true
      }
    default: return state
  }
}
const Payment = (props) => {

  const [state, dispatch] = useReducer(reducer, initialValue)
  const { clientID, token } = useContext(AuthContext)

  useEffect(() => {
    var render = true;
    if (state.isReload) {
      render && dispatch({ type: "LOADING" })
      const query = `
        query{
          listOutletPayment{
            id
            namapayment
          }
        }
      `
      GQL(query, clientID, token).then(resp => {
        if (resp.errors) throw resp.errors;
        dispatch({ type: "LOADED", data: resp.data.listOutletPayment })
      }).catch(err => {
        console.log(err);
        alert("Server tidak dapat memenuhi permintaan Anda saat ini. Silakan coba beberapa saat lagi");
        dispatch({ type: "ERROR", error: err })
      })

    }
    return () => {
      render = false;
    };
  }, [state.isReload])

  const addPayment = () => {
    props.history.push('/paymentdetail');
  }

  const handleEdit = (id) => {
    props.history.push(`/paymentdetail/${id}`)
  }

  const handleDelete = ({ id, namapayment }) => {
    const yakin = window.confirm(`Yakin akan menghapus metode payment ${namapayment}?`);
    if (yakin) {
      const myHeader = new Headers();
      myHeader.append("Accept", "application/json");
      myHeader.append("Content-Type", "application/json");
      myHeader.append("X-API-CLIENT-ID", clientID);
      myHeader.append("X-API-TOKEN", token);
      const myInit = {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify({ kodepayment: id })
      }
      const myRequest = new Request(`${process.env.REACT_APP_URL}/payment/hapus`, myInit);
      fetch(myRequest).then(res => res.json()).then(resp => {
        const { info, success } = resp;
        if (success) {
          alert("Payment berhasil dihapus");
          dispatch({ type: "DELETED"});
        } else {
          throw new Error(info);
        }
      }).catch(err => {
        console.log(err);
        dispatch({ type: "ERROR", error: err });
      })
    }
  }

  if (state.isLoading) return (<Spinner animation="border" role="status" className="spinner">
    <span className="sr-only">Loading...</span>
  </Spinner>)

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <h3 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom">Payment</h3>
          <Button variant="contained" color="primary" onClick={addPayment}>Tambah Payment</Button>
          <Paper style={{ marginTop: 20 }} className="table-responsive">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Nama Payment</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.data && state.data.map((el, idx) => (
                  <TableRow key={el.id}>
                    <TableCell>{idx + 1}</TableCell>
                    <TableCell>{el.namapayment}</TableCell>
                    <TableCell>
                      <Button variant="contained" style={{ backgroundColor: 'gray', marginRight: 10 }} onClick={handleEdit.bind(this, el.id)}>Edit</Button>
                      <Button variant="contained" style={{ backgroundColor: 'red', marginRight: 10 }} onClick={handleDelete.bind(this, el)}>Hapus</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Col>
      </Row>
    </Container>
  );
}

export default Payment;