import React, { useReducer, useContext, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import AuthContext from '../../context/auth-context'
import GQL from '../../GQL'
import { TextField, FormControlLabel, Radio, FormControl, FormLabel, RadioGroup, MenuItem, Button } from '@material-ui/core';
import './Sales.css';

const initialValue = {
  isLoading: false,
  isSaving: false,
  data: {
    email: '',
    password: '',
    status: 1,
    userin: '',
    userupt: '',
    jamin: '',
    jamupt: '',
    referencecode: '',
    bank: {
      kodebank: '',
      namabank: ''
    },
    cabang: '',
    norekening: '',
    namasales: '',
    alamat: '',
    nik: '',
    gender: '',
    nohp: ''
  },
  bank: [],
  isReload: true
}

const reducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        data: initialValue.data,
        bank: initialValue.bank,
        isLoading: true,
        isReload: true
      }
    case "LOADED":
      return {
        ...state,
        data: action.data,
        bank: action.bank,
        isLoading: false,
        isReload: false
      }
    case "ERROR":
      return {
        ...state,
        isLoading: false,
        isSaving: false,
        isReload: false
      }
    case "UPDATE":
      return {
        ...state,
        data: action.data
      }
    case "SAVING":
      return {
        ...state,
        isSaving: true
      }
    case "SAVED":
      return {
        ...state,
        isSaving: false,
        isReload: !action.isNew
      }
  }
}

const getStatus = (idstatus) => {
  switch (idstatus) {
    case 5:
      return "Aktif"
    case 8:
      return "Suspended"
    default: return "Aktif"
  }
}


const SalesDetail = (props) => {

  const [state, dispatch] = useReducer(reducer, initialValue)
  const { clientID, token } = useContext(AuthContext)

  useEffect(() => {
    var render = true
    const { email } = props.match.params;
    const querySales = state.isReload && email ? `
        sales(email:"${email}") { 
          email
          namasales
          alamat
          nik
          gender
          nohp
          status
          referencecode
          bank{
            kodebank
            namabank
          }
          cabang
          norekening
          alamat
          nik
          gender
          nohp
        }
    `: '';
    const queryBank = state.bank.length == 0 ? `
        listBank{
          kodebank
          namabank
        }
    ` : '';
    var query = `
      query{
        ${querySales}
        ${queryBank}
      }
    `
    if (querySales || queryBank) {
      GQL(query, clientID, token).then(resp => {
        if (resp.errors) throw resp.errors;
        var dataSales = resp.data.sales || state.data;
        var dataBank = resp.data.listBank || state.bank
        render && dispatch({ type: "LOADED", data: dataSales, bank: dataBank })
      }).catch(err => {
        console.log(err);
        render && dispatch({ type: "ERROR" });
      })
    }
    return () => {
      render = false
    };
  }, [state.isReload])

  const data = state.data;

  const namaHandler = (ev) => {
    const updateData = {
      ...data,
      namasales: ev.target.value
    }
    dispatch({ type: "UPDATE", data: updateData });
  }

  const alamatHandler = ev => {
    const updateData = {
      ...data,
      alamat: ev.target.value
    }
    dispatch({ type: "UPDATE", data: updateData });
  }

  const hpHandler = ev => {
    const updateData = {
      ...data,
      nohp: ev.target.value
    }
    dispatch({ type: "UPDATE", data: updateData });
  }

  const ktpHandler = ev => {
    const updateData = {
      ...data,
      nik: ev.target.value
    }
    dispatch({ type: "UPDATE", data: updateData });
  }

  const genderHandler = ev => {
    const updateData = {
      ...data,
      gender: ev.target.value
    }
    dispatch({ type: "UPDATE", data: updateData });
  }

  const bankHandler = ev => {
    const updateData = {
      ...data,
      bank: {
        kodebank: ev.target.value
      }
    }
    dispatch({ type: "UPDATE", data: updateData });
  }

  const cabangHandler = ev => {
    const updateData = {
      ...data,
      cabang: ev.target.value
    }
    dispatch({ type: "UPDATE", data: updateData });
  }

  const rekeningHandler = ev => {
    const updateData = {
      ...data,
      norekening: ev.target.value
    }
    dispatch({ type: "UPDATE", data: updateData });
  }

  const passwordHandle = ev => {
    const updateData = {
      ...data,
      password: ev.target.value
    }
    dispatch({ type: "UPDATE", data: updateData });
  }

  const emailHandle = ev => {
    const updateData = {
      ...data,
      email: ev.target.value
    }
    dispatch({ type: "UPDATE", data: updateData });
  }

  const handleSuspend = () => {
    const yakin = window.confirm(`Yakin akan mengsuspend akun ${state.data.namasales}?`);
    if (yakin) {
      dispatch({ type: "SAVING" });
      var variableBank = '';
      if (state.data.bank && state.data.kodebank !== '---') variableBank += `,bank:"${state.data.bank.kodebank}"`;
      if (state.data.cabang) variableBank += `,cabang:"${state.data.cabang}"`;
      if (state.data.norekening) variableBank += `,norekening:"${state.data.norekening}"`;
      const query = `
        mutation{
          updateSales(input:{email:"${state.data.email}",namasales:"${state.data.namasales}",alamat:"${state.data.alamat}",nik:"${state.data.nik}",gender:${state.data.gender},nohp:"${state.data.nohp}",status:8,${variableBank}})
        }
      `
      GQL(query, clientID, token).then(resp => {
        if (resp.errors) throw resp.errors;
        alert("Data berhasil disimpan")
        dispatch({ type: "SAVED" })
      }).catch(err => {
        console.log(err);
        alert("Server tidak dapat memproses data Anda saat ini. Cobalah beberapa saat lagi")
        dispatch({ type: "ERROR" })
      })
    }
  }

  const handleActive = () => {
    const yakin = window.confirm(`Yakin akan mengaktifkan kembali akun ${state.data.namasales}?`);
    if (yakin) {
      dispatch({ type: "SAVING" });
      var variableBank = '';
      if (state.data.bank && state.data.kodebank !== '---') variableBank += `,bank:"${state.data.bank.kodebank}"`;
      if (state.data.cabang) variableBank += `,cabang:"${state.data.cabang}"`;
      if (state.data.norekening) variableBank += `,norekening:"${state.data.norekening}"`;
      const query = `
        mutation{
          updateSales(input:{email:"${state.data.email}",namasales:"${state.data.namasales}",alamat:"${state.data.alamat}",nik:"${state.data.nik}",gender:${state.data.gender},nohp:"${state.data.nohp}",status:5,${variableBank}})
        }
      `
      GQL(query, clientID, token).then(resp => {
        if (resp.errors) throw resp.errors;
        alert("Data berhasil disimpan")
        dispatch({ type: "SAVED" })
      }).catch(err => {
        console.log(err);
        alert("Server tidak dapat memproses data Anda saat ini. Cobalah beberapa saat lagi")
        dispatch({ type: "ERROR" })
      })
    }
  }

  const handleSimpan = () => {
    dispatch({ type: "SAVING" });
    var variableBank = '';
    if (state.data.bank && state.data.kodebank !== '---') variableBank += `,bank:"${state.data.bank.kodebank}"`;
    if (state.data.cabang) variableBank += `,cabang:"${state.data.cabang}"`;
    if (state.data.norekening) variableBank += `,norekening:"${state.data.norekening}"`;
    const query = `
      mutation{
        createSales(input:{email:"${state.data.email}",namasales:"${state.data.namasales}",password:"${state.data.password}",alamat:"${state.data.alamat}",nik:"${state.data.nik}",gender:${state.data.gender},nohp:"${state.data.nohp}"${variableBank}})
      }
    `
    GQL(query, clientID, token).then(resp => {
      if (resp.errors) throw resp.errors;
      alert("Data berhasil disimpan")
      dispatch({ type: "SAVED", isNew: true })
      props.history.push(`/salesdetail/${state.data.email}`);
    }).catch(err => {
      console.log(err);
      alert("Server tidak dapat memproses data Anda saat ini. Cobalah beberapa saat lagi")
      dispatch({ type: "ERROR" })
    })
  }

  const handleUpdate = () => {
    dispatch({ type: "SAVING" });
    var variableBank = '';
    if (state.data.bank && state.data.kodebank !== '---') variableBank += `,bank:"${state.data.bank.kodebank}"`;
    if (state.data.cabang) variableBank += `,cabang:"${state.data.cabang}"`;
    if (state.data.norekening) variableBank += `,norekening:"${state.data.norekening}"`;
    if(state.data.password) variableBank += `,password:"${state.data.password}"`
    const query = ` 
      mutation{
        updateSales(input:{email:"${state.data.email}",namasales:"${state.data.namasales}",alamat:"${state.data.alamat}",nik:"${state.data.nik}",gender:${state.data.gender},nohp:"${state.data.nohp}"${variableBank}})
      }
    `
    GQL(query, clientID, token).then(resp => {
      if (resp.errors) throw resp.errors;
      alert("Data berhasil disimpan")
      dispatch({ type: "SAVED" })
    }).catch(err => {
      console.log(err);
      alert("Server tidak dapat memproses data Anda saat ini. Cobalah beberapa saat lagi")
      dispatch({ type: "ERROR" })
    })
  }

  const allowTextEditing = state.isSaving || state.isLoading || state.data.status == 8;

  return (
    <Container>
      <Row>
        <Col>
          <h3 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom">Sales</h3>
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col xs={12} md={4}>
          <h4 className="border-bottom">Informasi Umum</h4>
          <TextField
            className="profilInput"
            id="referencecode"
            label="Kode Referensi"
            value={data.referencecode}
            contentEditable={false}
            fullWidth
            disabled
          />
          <TextField
            className="profilInput"
            id="email"
            label="Email"
            value={data.email}
            contentEditable={false}
            fullWidth
            onChange={emailHandle}
            disabled={!!props.match.params.email}
          />
          <TextField
            className="profilInput"
            id="password"
            label="Password"
            value={data.password || ''}
            contentEditable={false}
            fullWidth
            type="password"
            onChange={passwordHandle}
          />
          <FormControl className="profilInput">
            <FormLabel>Gender</FormLabel>
            <RadioGroup
              aria-label="gender"
              name="gender"
              value={data.gender}
              style={{ display: 'flex', flexDirection: 'row' }}
              onChange={genderHandler}
              disabled={state.isSaving}
            >
              <FormControlLabel value="MALE" control={<Radio />} label="Male" />
              <FormControlLabel value="FEMALE" control={<Radio />} label="Female" />
            </RadioGroup>
          </FormControl>
          <TextField
            className="profilInput"
            id="status"
            label="Status"
            value={getStatus(data.status)}
            contentEditable={false}
            fullWidth
            disabled
          />
        </Col>
        <Col xs={12} md={4}>
          <h4 className="border-bottom">Data Pribadi</h4>
          <TextField
            className="profilInput"
            id="nik"
            label="No Identitas (KTP)"
            value={data.nik}
            fullWidth
            onChange={ktpHandler}
            disabled={allowTextEditing}
          />
          <TextField
            className="profilInput"
            id="namasales"
            label="Nama Lengkap"
            value={data.namasales}
            fullWidth
            onChange={namaHandler}
            disabled={allowTextEditing}
          />
          <TextField
            className="profilInput"
            id="alamat"
            label="Alamat"
            value={data.alamat}
            fullWidth
            multiline={true}
            rows={2}
            onChange={alamatHandler}
            disabled={allowTextEditing}
          />
          <TextField
            className="profilInput"
            id="nohp"
            label="No Handphone"
            value={data.nohp}
            fullWidth
            onChange={hpHandler}
            disabled={allowTextEditing}
          />
        </Col>
        <Col xs={12} md={4}>
          <h4 className="border-bottom">Informasi Bank</h4>
          <TextField
            className="profilInput"
            id="bank.kodebank"
            label="Bank"
            value={data.bank && data.bank.kodebank || "---"}
            fullWidth
            select
            onChange={bankHandler}
            disabled={allowTextEditing}
          >
            <MenuItem value="---">-- PILIH BANK --</MenuItem>
            {state.bank.length > 0 && state.bank.map((el, index) => (
              <MenuItem key={index.toString()} value={el.kodebank}>
                {el.namabank}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className="profilInput"
            id="cabang"
            label="Kantor Cabang"
            value={data.cabang || ''}
            fullWidth
            onChange={cabangHandler}
            disabled={allowTextEditing}
          />
          <TextField
            className="profilInput"
            id="norekening"
            label="Nomor Rekening"
            value={data.norekening || ''}
            fullWidth
            onChange={rekeningHandler}
            disabled={allowTextEditing}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col xs={12}>
          <div className="text-center">
            {props.match.params.email && (
              <Button variant="contained" color="primary" onClick={handleUpdate} disabled={state.isSaving || state.isLoading}>
                Update
            </Button>
            ) || (
              <Button variant="contained" color="primary" onClick={handleSimpan} disabled={state.isSaving || state.isLoading}>
                Simpan
              </Button>
            )}
            {data.status == 5 && (
              <Button variant="contained" style={{ backgroundColor: 'red', marginLeft: 10 }} onClick={handleSuspend} disabled={state.isSaving || state.isLoading}>
                Suspend
              </Button>
            )}
            {data.status == 8 && (
              <Button variant="contained" style={{ backgroundColor: 'green', marginLeft: 10 }} onClick={handleActive} disabled={state.isSaving || state.isLoading}>
                Aktifkan Kembali
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default SalesDetail;