import GQL from '../../GQL';
const UserLaporan = {
    loadUserOutlet: (clientID, token, email) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    outletUserLaporan(username:"${email}"){
                        kodeoutlet
                        namaoutlet
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.outletUserLaporan) return (resolve(resp.data.outletUserLaporan));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    loadOutlet: (clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    listAlloutletAktif{
                        kodeoutlet
                        namaoutlet
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.listAlloutletAktif) return (resolve(resp.data.listAlloutletAktif));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    savaUserOutlet: (clientID, token, email, kodeoutlet) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    simpanOutletUser(username:"${email}", kodeoutlet:"${kodeoutlet}")
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.simpanOutletUser) return (resolve(resp.data.simpanOutletUser));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    hapusUserOutlet: (clientID, token, email, kodeoutlet) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    hapusOutletUser(username:"${email}", kodeoutlet:"${kodeoutlet}")
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.hapusOutletUser) return (resolve(resp.data.hapusOutletUser));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
}

export default UserLaporan;