/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Row, Col, Table } from 'react-bootstrap';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';


const Export = (props) => {
    const listData = props.data
    const isReload = props.isLoading
    const name = props.name
    return (
        <Row>
            <Col xs={12}>
                {!isReload && (
                    <ReactHTMLTableToExcel
                        className="buttonExportExcel"
                        table="exportData"
                        filename={name}
                        sheet="Sheet1"
                        buttonText=" Export Excel " />
                )}
                <Table id="exportData" striped bordered hover responsive="lg" style={{ display: "none" }}>
                    <thead>
                        <tr className="colorWhite bgHeaderTabel tabelCenter">
                            <th>Nama Outlet</th>
                            <th>Expiry Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listData.map((el, i) => {
                            return (
                                <tr key={i}>
                                    <td>{el.namaoutlet}</td>
                                    <td>{el.expiryDate}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
}
export default Export;