import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import validate from 'validate.js'
import mControl from './UserLaporan.ctrl'


const Modal = (props) => {
    const dataSelect = props.dataSelect
    const [isUpdate, setIsUpdate] = useState(false)
    const [form, setForm] = useState({
        emaail: '',
        namacustomer: '',
        password: '',
        nohp: '',
        alamat: '',
        gender: ''
    })
    useEffect(() => {
        if (dataSelect.email) {
            setIsUpdate(true)
            setForm(dataSelect)
        }
        return () => {

        }
    }, [])

    const handleClose = () => {
        props.setOpen(false);
    };

    const validateForm = () => {
        const { email, namacustomer, nohp, alamat, gender } = form;
        const promForm = new Promise((resolve, reject) => {
            const constraint = {
                email: {
                    email: {
                        message: "Email tidak valid"
                    }
                },
                namacustomer: {
                    length: {
                        minimum: 3,
                        message: "Nama minimal terdiri dari 3 huruf"
                    },
                },
                nohp: {
                    length: {
                        minimum: 8,
                        message: "Nomor HP terdiri dari 8 digit"
                    },
                },
                alamat: {
                    length: {
                        minimum: 5,
                        message: "Alamat terdiri dari 5 huruf"
                    },
                },
                gender: {
                    length: {
                        minimum: 1,
                        message: "Gender Wajib isi"
                    },
                }
            }
            const validator = validate({ email, namacustomer, nohp, alamat, gender }, constraint, { fullMessages: false });
            if (validator === undefined) {
                resolve();
            } else {
                reject(validator);
            }
        })
        return Promise.all([promForm]);
    }

    const simpan = () => {
        validateForm().then(() => {
            if (form.password < 5) return alert("Password minimal 5 digit")
            const { email, namacustomer, nohp, alamat, gender, password } = form;
            const _address = alamat.replace(/\n/g, "<BR>");
            mControl.savaUserLaporan(props.clientID, props.token, email, namacustomer, nohp, _address, gender, password).then(resp => {
                alert("Data Berhasil Disimpan")
                handleClose()
                props.setIsLoading(true)
            }).catch(err => {
                if (err.length > 0) {
                    alert(err[0].message)
                } else if (typeof err) {
                    alert(err.message)
                } else {
                    console.log(err);
                }
            })
        }).catch(err => {
            console.log(err)
            const keys = Object.keys(err);
            var errorMessage = "";
            for (const key of keys) {
                errorMessage += err[key] + "\n";
            }
            // console.log(err);
            alert(errorMessage);
        })
    }

    const update = () => {
        validateForm().then(() => {
            const { email, namacustomer, nohp, alamat, gender } = form;
            const _address = alamat.replace(/\n/g, "<BR>");
            mControl.updateUserLaporan(props.clientID, props.token, email, namacustomer, nohp, _address, gender).then(resp => {
                alert("Data Berhasil Diupdate")
                handleClose()
                props.setIsLoading(true)
            }).catch(err => {
                if (err.length > 0) {
                    alert(err[0].message)
                } else if (typeof err) {
                    alert(err.message)
                } else {
                    console.log(err);
                }
            })
        }).catch(err => {
            console.log(err)
            const keys = Object.keys(err);
            var errorMessage = "";
            for (const key of keys) {
                errorMessage += err[key] + "\n";
            }
            // console.log(err);
            alert(errorMessage);
        })

    }
    const handleChange = (ev) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        var newForm = { ...form }
        newForm[key] = value;
        setForm(newForm);
    }
    return (
        <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{isUpdate ? "Edit" : "Add"} User</DialogTitle>
            <DialogContent>
                <TextField
                    required
                    autoFocus
                    margin="dense"
                    id="email"
                    label="Email"
                    fullWidth
                    multiline={true}
                    value={form.email}
                    onChange={handleChange}
                    disabled={isUpdate ? true : false} />
                <TextField
                    required
                    margin="dense"
                    id="namacustomer"
                    label="Nama"
                    fullWidth
                    multiline={true}
                    value={form.namacustomer}
                    onChange={handleChange} />
                {!isUpdate && (
                    <TextField
                        autoComplete={false}
                        required
                        margin="dense"
                        id="password"
                        label="Password"
                        fullWidth
                        value={form.password}
                        type="password"
                        onChange={handleChange} />
                )}
                <TextField
                    required
                    margin="dense"
                    id="nohp"
                    label="Nomor HP"
                    fullWidth
                    multiline={true}
                    value={form.nohp}
                    onChange={handleChange} />
                <TextField
                    required
                    margin="dense"
                    id="alamat"
                    label="Alamat"
                    fullWidth
                    rows={2}
                    multiline={true}
                    value={form.alamat}
                    onChange={handleChange} />
                <FormControl component="fieldset">
                    <FormLabel component="legend">Gender</FormLabel>
                    <RadioGroup
                        aria-label="gender"
                        name="gender"
                        onChange={handleChange}
                        value={form.gender}
                    >
                        <FormControlLabel value="FEMALE" control={<Radio />} label="Female" />
                        <FormControlLabel value="MALE" control={<Radio />} label="Male" />
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">Cancel</Button>
                {isUpdate ? (
                    <Button onClick={update} color="secondary">Update</Button>
                ) : (
                        <Button onClick={simpan} color="secondary">Simpan</Button>
                    )}
            </DialogActions>
        </Dialog>
    )
}
export default Modal;
