import GQL from '../../GQL';
const VoucherCrl = {
    loadVoucher: (clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    listVoucher{
                        kodevoucher
                        namavoucher
                        tanggalMulai
                        tanggalSelesai
                        jumlahVoucher
                        voucherTerpakai
                        statusid
                        freeTrialDay
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.listVoucher) return (resolve(resp.data.listVoucher));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    loadVoucherUsage: (clientID, token) => {
        return new Promise((resolve, reject) => {
            const query = `
                query {
                    allVoucherUsage{
                        urut
                        kodevoucher
                        kodeoutlet
                        tanggalClaim
                        namavoucher
                        namaoutlet
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.allVoucherUsage) return (resolve(resp.data.allVoucherUsage));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    saveVoucher: (clientID, token, namavoucher, tanggalMulai, tanggalSelesai, jumlahVoucher, freeTrialDay, kodevoucher) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    createVoucher(input:{
                        namavoucher:"${namavoucher}",
                        tanggalMulai:"${tanggalMulai}",
                        tanggalSelesai:"${tanggalSelesai}",
                        jumlahVoucher:${jumlahVoucher},
                        freeTrialDay:${freeTrialDay},
                        kodevoucher:"${kodevoucher || " "}"
                    }){
                        kodevoucher
                        namavoucher
                        tanggalMulai
                        tanggalSelesai
                        jumlahVoucher
                        statusid
                        userin
                        userupt
                        jamin
                        jamupt
                        freeTrialDay
                    }
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.createVoucher) return (resolve(resp.data.createVoucher));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    updateVoucher: (clientID, token,  namavoucher, tanggalMulai, tanggalSelesai, jumlahVoucher, freeTrialDay, kodevoucher) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    updateVoucher(input:{
                        namavoucher:"${namavoucher}",
                        tanggalMulai:"${tanggalMulai}",
                        tanggalSelesai:"${tanggalSelesai}",
                        jumlahVoucher:${jumlahVoucher},
                        freeTrialDay:${freeTrialDay},
                        kodevoucher:"${kodevoucher}"
                    })
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.updateVoucher) return (resolve(resp.data.updateVoucher));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
    hapusVoucher: (clientID, token, kodevoucher) => {
        return new Promise((resolve, reject) => {
            const query = `
                mutation{
                    updateVoucher(input:{kodevoucher:"${kodevoucher}", statusid:0})
                }
            `
            GQL(query, clientID, token).then(resp => {
                if (resp.errors) return (reject(resp.errors));;
                if (resp.data && resp.data.hapusUserCustomer) return (resolve(resp.data.hapusUserCustomer));
                return (resolve([]))
            }).catch(err => {
                return (reject(err));
            })
        })
    },
}

export default VoucherCrl;