import React, { useState, useEffect, useContext } from 'react'
import { TextField, Select, FormControl, FormControlLabel, MenuItem, InputLabel, Checkbox, Button } from '@material-ui/core';
import { Container, Row, Col } from 'react-bootstrap';
import listProvince from '../Constants/Province';
import Kota from '../Constants/Kota';
import GQL from '../../GQL';
import AuthContext from '../../context/auth-context'
import NoImage from '../../NoImage'
import PublishIcon from '@material-ui/icons/Publish';
import OutletLokasi from './OutletLokasi'
import Geolocation from 'react-geolocation'
import CircularProgress from '@material-ui/core/CircularProgress'

import GoogleMapReact from 'google-map-react';

const { listKota } = Kota;
const OutletForm = (props) => {

    const [listSales, setListSales] = useState([])
    const [listServer, setListServer] = useState([])
    const { clientID, token } = useContext(AuthContext)
    const [center, setCenter] = useState({ lat: 0, lng: 0 })
    const [isOpenMap, setIsOpenMap] = useState(false)
    const [isSaveGenerateMYSQL, setIsSaveGenerateMYSQL] = useState(false)
    useEffect(() => {
        var render = true;
        const query = `
            query{
                listSales{
                    referencecode
                    namasales
                }
                listServer{
                    kodeserver
                    namaserver
                }
            }
        `
        GQL(query, clientID, token).then(resp => {
            if (resp.errors) throw resp.errors;
            if (render) {
                setListSales(resp.data.listSales)
                setListServer(resp.data.listServer)
            }
        }).catch(err => {
            render && alert("Server tidak dapat memenuhi permintaan Anda saat ini")
            console.log(err);
        })
        return () => {
            render = false;
        };
    }, [])
    useEffect(() => {
        if (props.data.latitude && props.data.longitude) {
            setCenter({
                lat: props.data.latitude * 1,
                lng: props.data.longitude * 1
            })
        }
        return () => {

        }
    }, [props.data.longitude, props.data.longitude])
    const getKota = () => {
        if (props.data.province && listKota[props.data.province]) return listKota[props.data.province];
        return [];
    }
    const handleChange = (ev) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        if (['isTrialMode', 'connectHoman', 'usePassti', 'useCashlez', 'isTes', 'isOpname', 'useQREN', 'useBarangOutlet', 'useDelivery', 'useIposStock', 'usePoktan'].indexOf(key) > -1) {
            value = !!!props.data[key];
        } else if (['salesreferencecode'].indexOf(key) > -1) {
            value = value || null;
        } else if (ev.target.type == "number") {
            value = +value;
        }
        props.handleChange(key, value)
    }
    const salesreferencecode = props.data.salesreferencecode || '';
    const uploadGambar = (ev) => {
        if (ev.target.files[0].size / 1024 / 1024 >= 5) {
            alert("Gambar harus lebih kecil dari 5MB")
            return
        } else {
            const formData = new FormData();
            formData.append('file', ev.target.files[0]);
            formData.append('kodeoutlet', props.data.kodeoutlet);
            return fetch(`${process.env.REACT_APP_URL}/upload/upload-outlet`, {
                method: "POST",
                headers: {
                    'X-API-CLIENT-ID': props.clientID,
                    'X-API-TOKEN': props.token,
                },
                body: formData
            }).then(res => res.json())
                .then(resp => {
                    if (resp.success) {
                        alert(resp.info)
                        props.dispatch({ type: "SAVED" });
                    } else {
                        alert(resp.info)
                    }
                }).catch(err => {
                    if (err.length > 0) {
                        alert(`Gagal Simpan :  ${err[0].message}`)
                    } else if (err === 'error') {
                        alert(err.message)
                    } else {
                        console.log(err);
                    }
                })
        }
    }
    const renderMarkers = (map, maps) => {
        new maps.Marker({
            position: center,
            map,
            title: 'Lokasi yang anda pilih'
        });
    }
    const handleGenerateDBMYSQL = () => {
        if (props.data.mysqlHost.length <= 0) return alert("Wajib isi MySQL")
        if (props.data.mysqlDb.length <= 0) return alert("Wajib isi MySQL Database")
        if (props.data.mysqlUser.length <= 0) return alert("Wajib isi MySQL User")
        if (props.data.email.length <= 0) return alert("Wajib isi Alamat Email")
        setIsSaveGenerateMYSQL(true)
        const query = `
        mutation{
            createDBMysql(mysqlHost:"${props.data.mysqlHost}",mysqlDb: "${props.data.mysqlDb}",mysqlUser: "${props.data.mysqlUser}",mysqlPwd: "${props.data.mysqlPwd}", email:"${props.data.email}")
        }
    `
        GQL(query, clientID, token).then(resp => {
            if (resp.errors) throw resp.errors;
            if (resp.data.createDBMysql) {
                alert("Berhasil Generate Database")
                setIsSaveGenerateMYSQL(false)
            } else {
                alert("Gagal Generate Database")
                setIsSaveGenerateMYSQL(false)
            }
        }).catch(err => {
            setIsSaveGenerateMYSQL(false)
            if (err.length > 0) {
                return alert(err[0].message)
            }
        })
    }
    return (
        <Container>
            <Row>
                <Col xs={12} lg={4}>
                    <h3 className="border-bottom" style={{ marginTop: 20, paddingBottom: 10 }}>Informasi Outlet</h3>
                    <TextField
                        id="kodeoutlet"
                        label="Kode Outlet (System Generated)"
                        value={props.data.kodeoutlet}
                        contentEditable={false}
                        fullWidth
                        disabled
                    />
                    <TextField
                        id="namaoutlet"
                        label="Nama Outlet"
                        value={props.data.namaoutlet}
                        fullWidth
                        required
                        onChange={handleChange}
                        type="text"
                    />
                    <TextField
                        id="database"
                        label="Database (System Generated)"
                        value={props.data.database}
                        contentEditable={false}
                        fullWidth
                        disabled
                    />
                    <TextField
                        id="userdb"
                        label="User Database (System Generated)"
                        value={props.data.userdb}
                        contentEditable={false}
                        fullWidth
                        disabled
                    />
                    <TextField
                        id="pwddb"
                        label="Password Database (System Generated)"
                        value={props.data.pwddb}
                        contentEditable={false}
                        fullWidth
                        disabled
                    />
                    <TextField
                        id="tax"
                        label="Tax"
                        value={props.data.tax}
                        fullWidth
                        required
                        type="number"
                        onChange={handleChange}
                    />
                    <TextField
                        id="service"
                        label="Service"
                        value={props.data.service}
                        fullWidth
                        required
                        type="number"
                        onChange={handleChange}
                    />
                    <FormControl fullWidth={true}>
                        <InputLabel>Sales</InputLabel>
                        <Select
                            name="salesreferencecode"
                            value={salesreferencecode}
                            onChange={handleChange}
                        >
                            <MenuItem value={''}>No Sales</MenuItem>
                            {listSales.map(el => (
                                <MenuItem key={el.referencecode} value={el.referencecode}>{el.namasales}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {/* {props.data.isTes && ( */}
                    <FormControl fullWidth={true}>
                        <FormControlLabel
                            control={
                                <Checkbox id="isTes" checked={props.data.isTes ? true : false}
                                    onChange={handleChange}
                                />
                            }
                            label="Outlet Tes"
                        />
                    </FormControl>
                    {/* )} */}
                    <FormControl fullWidth={true}>
                        <FormControlLabel
                            control={
                                <Checkbox id="isOpname" checked={props.data.isOpname ? true : false}
                                    onChange={handleChange}
                                />
                            }
                            label="Menggunakan Opname"
                        />
                    </FormControl>
                </Col>
                <Col xs={12} lg={4}>
                    <h3 className="border-bottom" style={{ marginTop: 20, paddingBottom: 10 }}>Informasi Pengguna</h3>
                    <TextField
                        id="fullName"
                        label="Nama Lengkap"
                        value={props.data.fullName}
                        fullWidth
                        required
                        onChange={handleChange}
                    />
                    <TextField
                        id="email"
                        label="Email"
                        value={props.data.email}
                        fullWidth
                        required
                        type="email"
                        onChange={handleChange}
                    />
                    <TextField
                        id="phoneNumber"
                        label="Telp"
                        value={props.data.phoneNumber}
                        fullWidth
                        required
                        type="telp"
                        onChange={handleChange}
                    />
                    <TextField
                        id="address"
                        label="Alamat"
                        value={props.data.address}
                        fullWidth
                        required
                        multiline={true}
                        rows={2}
                        onChange={handleChange}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <h3 className="border-bottom" style={{ marginTop: 20, paddingBottom: 10 }}>Lokasi Pengguna</h3>
                    <FormControl required fullWidth={true}>
                        <InputLabel>Negara</InputLabel>
                        <Select
                            name="country"
                            value={props.data.country}
                            required
                            onChange={handleChange}
                        >
                            <MenuItem value={'IDN'}>Indonesia</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl required fullWidth={true}>
                        <InputLabel>Propinsi</InputLabel>
                        <Select
                            name="province"
                            value={props.data.province}
                            required
                            onChange={handleChange}
                        >
                            {listProvince.map((el, idx) => (
                                <MenuItem key={idx.toString()} value={el}>{el}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl required fullWidth={true}>
                        <InputLabel>Kota</InputLabel>
                        <Select
                            name="city"
                            value={props.data.city}
                            required
                            onChange={handleChange}
                        >
                            {getKota().map((el, idx) => (
                                <MenuItem key={idx.toString()} value={el}>{el}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        id="zipCode"
                        label="Kode Pos"
                        value={props.data.zipCode}
                        fullWidth
                        required
                        onChange={handleChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h3 className="border-bottom" style={{ marginTop: 20, paddingBottom: 10 }}>Konfigurasi Outlet</h3>
                </Col>
                <Col xs={12} lg={6}>
                    <FormControl fullWidth={true}>
                        <InputLabel>Pilih Server</InputLabel>
                        <Select
                            name="server"
                            value={props.data.server}
                            onChange={handleChange}
                        >
                            {listServer.map(el => (
                                <MenuItem key={el.kodeserver} value={el.kodeserver}>{el.namaserver}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth={true}>
                        <FormControlLabel
                            control={
                                <Checkbox id="isTrialMode" checked={props.data.isTrialMode}
                                    value={!props.data.isTrialMode}
                                    onChange={handleChange}
                                />
                            }
                            label='Trial Mode'
                        />
                    </FormControl>
                    {props.data.isTrialMode && (
                        <React.Fragment>
                            <TextField
                                id="trialKategori"
                                label="Limit Kategori"
                                value={props.data.trialKategori}
                                fullWidth
                                required
                                type="number"
                                onChange={handleChange}
                            />
                            <TextField
                                id="trialSubKategori"
                                label="Limit Sub Kategori"
                                value={props.data.trialSubKategori}
                                fullWidth
                                required
                                type="number"
                                onChange={handleChange}
                            />
                            <TextField
                                id="trialBarang"
                                label="Limit Barang"
                                value={props.data.trialBarang}
                                fullWidth
                                required
                                type="number"
                                onChange={handleChange}
                            />
                            <TextField
                                id="trialTransaksiPerDay"
                                label="Limit Transaksi Per Hari"
                                value={props.data.trialTransaksiPerDay}
                                fullWidth
                                required
                                type="number"
                                onChange={handleChange}
                            />
                        </React.Fragment>
                    )}
                </Col>
                <Col xs={12} lg={6}>
                    <FormControl fullWidth={true}>
                        <FormControlLabel
                            control={
                                <Checkbox id="connectHoman" checked={props.data.connectHoman}
                                    onChange={handleChange}
                                />
                            }
                            label="Hubungkan dengan Homan System"
                        />
                    </FormControl>
                    <FormControl fullWidth={true}>
                        <FormControlLabel
                            control={
                                <Checkbox id="useBarangOutlet" checked={props.data.useBarangOutlet}
                                    onChange={handleChange}
                                />
                            }
                            label="Data Barang masing-masing outlet"
                        />
                    </FormControl>
                    {props.data.connectHoman && (
                        <React.Fragment>
                            <TextField
                                id="mysqlHost"
                                label="MySQL Host"
                                value={props.data.mysqlHost}
                                fullWidth
                                required
                                onChange={handleChange}
                            />
                            <TextField
                                id="mysqlDb"
                                label="MySQL Database"
                                value={props.data.mysqlDb}
                                fullWidth
                                required
                                onChange={handleChange}
                            />
                            <TextField
                                id="mysqlUser"
                                label="MySQL User"
                                value={props.data.mysqlUser}
                                fullWidth
                                required
                                onChange={handleChange}
                            />
                            <TextField
                                id="mysqlPwd"
                                label="MySQL Password"
                                value={props.data.mysqlPwd}
                                fullWidth
                                required={false}
                                type="password"
                                onChange={handleChange}
                            />
                            <Button style={{ marginTop: 5 }} onClick={handleGenerateDBMYSQL} fullWidth variant="contained" disabled={isSaveGenerateMYSQL} color="secondary">
                                {isSaveGenerateMYSQL ? <span> <CircularProgress color="secondary" /> Proses generate Database...</span> : "Generate Database Mysql"}</Button>
                        </React.Fragment>
                    )}
                </Col>
                <Col xs={12}>
                    <FormControl fullWidth={true}>
                        <FormControlLabel
                            control={
                                <Checkbox id="usePassti" checked={props.data.usePassti}
                                    onChange={handleChange}
                                />
                            }
                            label="Menggunakan gateway PASSTI"
                        />
                    </FormControl>
                    <FormControl fullWidth={true}>
                        <FormControlLabel
                            control={
                                <Checkbox id="useCashlez" checked={props.data.useCashlez}
                                    onChange={handleChange}
                                />
                            }
                            label="Menggunakan gateway CASHLEZ"
                        />
                    </FormControl>
                    <FormControl fullWidth={true}>
                        <FormControlLabel
                            control={
                                <Checkbox id="useQREN" checked={props.data.useQREN}
                                    onChange={handleChange}
                                />
                            }
                            label="Menggunakan gateway QREN"
                        />
                    </FormControl>
                    <FormControl fullWidth={true}>
                        <FormControlLabel
                            control={
                                <Checkbox id="useIposStock" checked={props.data.useIposStock}
                                    onChange={handleChange}
                                />
                            }
                            label="Menggunakan iPOS Stock"
                        />
                    </FormControl>
                    <FormControl fullWidth={true}>
                        <FormControlLabel
                            control={
                                <Checkbox id="usePoktan" checked={props.data.usePoktan}
                                    onChange={handleChange}
                                />
                            }
                            label="Menggunakan POKTAN"
                        />
                    </FormControl>
                    <TextField
                        id="jumlahdevice"
                        label="Limit Device"
                        value={props.data.jumlahdevice}
                        fullWidth
                        required
                        type="number"
                        onChange={handleChange}
                    />
                    {props.data.kodeoutlet && (
                        <FormControl fullWidth={true}>
                            <FormControlLabel
                                control={
                                    <Checkbox id="useDelivery" checked={props.data.useDelivery}
                                        onChange={handleChange}
                                    />
                                }
                                label="Menggunakan Delivery"
                            />
                        </FormControl>
                    )}
                    {props.data.useDelivery && (
                        <React.Fragment>
                            <Row>
                                <Col xs={12} md={4}>
                                    <TextField
                                        id="limitDelivery"
                                        label="Limit Delivery"
                                        value={props.data.limitDelivery}
                                        fullWidth
                                        required
                                        type="number"
                                        onChange={handleChange}
                                    />
                                </Col>
                                <Col xs={12} md={4}>
                                    <TextField
                                        id="gojek"
                                        label="Link Gojek"
                                        value={props.data.gojek}
                                        fullWidth
                                        required
                                        onChange={handleChange}
                                    />
                                </Col>
                                <Col xs={12} md={4}>
                                    <TextField
                                        id="grab"
                                        label="Link Grab"
                                        value={props.data.grab}
                                        fullWidth
                                        required
                                        onChange={handleChange}
                                    />
                                </Col>
                                <Col xs={12} md={4}>
                                    <TextField
                                        id="latitude"
                                        label="Latitude (Lokasi)"
                                        value={props.data.latitude}
                                        fullWidth
                                        required
                                        onChange={handleChange}
                                    />
                                </Col>
                                <Col xs={12} md={4}>
                                    <TextField
                                        id="longitude"
                                        label="Longitude (Lokasi)"
                                        value={props.data.longitude}
                                        fullWidth
                                        required
                                        onChange={handleChange}
                                    />
                                </Col>
                                <Col xs={12} md={4}>
                                    <Geolocation />
                                    {((props.data.longitude && props.data.latitude) && (props.data.longitude !== "null" && props.data.longitude !== "null")) ? (
                                        <div className='google-map' style={{ height: '200px', width: '100%' }}>
                                            <GoogleMapReact
                                                bootstrapURLKeys={{ key: process.env.REACT_APP_KEYMAP }}
                                                defaultCenter={center}
                                                defaultZoom={18}
                                                yesIWantToUseGoogleMapApiInternals
                                                onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                                            >
                                            </GoogleMapReact>
                                        </div>
                                    ) : (
                                        <p className="pilihLokasi text-center" onClick={() => setIsOpenMap(true)}>Saat ini anda belum pilih lokasi, pilih lokasi terlebih dahulu.</p>
                                    )}

                                    <h6 className="colorAktif tangan text-center" onClick={() => setIsOpenMap(true)}>Ubah Lokasi</h6>
                                </Col>
                                <Col xs={12} md={6}>
                                    <img className="img-form" src={props.data.gambar || `data:image/jpeg;base64,${NoImage}`} />
                                    <input
                                        id="isPhoto"
                                        label="Gambar"
                                        type="file"
                                        onChange={uploadGambar.bind(this)}
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                    />
                                    <label htmlFor="isPhoto">
                                        <Button variant="contained" color="primary" component="span" ><PublishIcon /> Upload Photo</Button>
                                    </label>
                                </Col>
                            </Row>
                            {isOpenMap && (
                                <OutletLokasi longitude={props.data.longitude} latitude={props.data.latitude} kodeoutlet={props.data.kodeoutlet} open={isOpenMap} setOpen={setIsOpenMap} dispatch={props.dispatch} clientID={clientID} token={token} />
                            )}
                        </React.Fragment>
                    )}
                </Col>
            </Row>
        </Container>
    )
}
export default OutletForm;
