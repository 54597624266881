const GQL = (query, clientID, token) => {
  const myHeader = new Headers();
  if(clientID) myHeader.append("X-API-CLIENT-ID", clientID);
  if(token) myHeader.append("X-API-TOKEN",token)
  myHeader.append("Accept", "application/json");
  myHeader.append("Content-Type", "application/json");

  const myInit = {
    method: "POST",
    headers: myHeader,
    body: JSON.stringify({
      query: query
    })
  }
  const myRequest = new Request(`${process.env.REACT_APP_URL}/graphql/`, myInit); return fetch(myRequest).then(res => res.json());
}
export default GQL;