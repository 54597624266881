import React, { useReducer, useEffect, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { TextField, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button, Dialog, AppBar, IconButton, Toolbar, Typography, Checkbox, FormControlLabel, Switch } from '@material-ui/core';
import AuthContext from '../../context/auth-context'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import moment from 'moment'
import NumberFormat from 'react-number-format';
import GQL from '../../GQL';
import CloseIcon from '@material-ui/icons/Close';
const initialValue = {
  data: {
    notrans: '',
    tanggal: moment(),
    status: 1,
    userin: '',
    jamin: '',
    userupt: '',
    jamupt: '',
    detail: []
  },
  isReload: true,
  isLoading: false,
  isSaving: false,
  error: null,
  openDialog: false,
  isLoadingDialog: false,
  dataImport: []
}

const reducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...initialValue,
        isLoading: true
      }
    case "LOADED":
      return {
        ...state,
        isLoading: false,
        data: action.data
      }
    case "ERROR":
      return {
        ...state,
        isLoading: false,
        isSaving: false,
        isReload: false,
        error: action.error
      }
    case "OPENDIALOG":
      return {
        ...state,
        openDialog: true
      }
    case "CLOSEDIALOG":
      return {
        ...state,
        openDialog: false
      }
    case "LOADDIALOG":
      return {
        ...state,
        isLoadingDialog: true,
        dataImport: []
      }
    case "LOADEDDIALOG":
      return {
        ...state,
        isLoadingDialog: false,
        dataImport: action.data

      }
    case "DIALOGERROR":
      return {
        ...state,
        isLoadingDialog: false
      }
    case "UPDATEDIALOG":
      return {
        ...state,
        dataImport: action.data
      }
    case "SAVING":
      return{
        ...state,
        isSaving:true
      }
    case "SAVED":
      return{
        ...state,
        isSaving:false
      }
    case "UPDATE":
      return {
        ...state,
        data:action.data
      }
    default: return state;
  }
}



const getStatus = (status) => {
  switch (status) {
    case 1:
      return "Aktif"
    case 8:
      return "Batal"
    default: return "Invalid"
  }
}

const KomisiDetail = (props) => {
  const [state, dispatch] = useReducer(reducer, initialValue)
  const { clientID, token } = useContext(AuthContext)

  useEffect(() => {
    var render = true
    const { notrans } = props.match.params;
    if (render && notrans && state.isReload) {
      render && dispatch({ type: "LOADING" })
      const query = `
        query{
          komisi(notrans:"${notrans}"){
            notrans
            tanggal
            status
            userin
            userupt
            jamin
            jamupt
            detail{
              id_trans{
                payment{
                  namapayment
                }
                paymentDeskripsi
                paymentAmount
                expiryDate
                userin
                userupt
                tanggalPencairanKomisi
                transferKe
                noreferensi
                nilaikomisi 
              }
            }
          }
        }
      `
      GQL(query, clientID, token).then(resp => {
        if (resp.errors) throw resp.errors;
        dispatch({ type: "LOADED", data: resp.data.komisi })
      }).catch(err => {
        console.log(err);
        alert("Server tidak dapat memenuhi permintaan Anda saat ini. Silakan coba beberapa saat lagi");
        dispatch({ type: "ERROR", error: err });
      })
    }
    return () => {
      render = false
    };
  }, [props.match.params.id, state.isReload])

  useEffect(() => {
    var render = true
    if (state.openDialog && render) {
      dispatch({ type: "LOADDIALOG" })
      const query = `
        query{
          listTransBelumKomisi{
            urut
            noreferensi
            nilaikomisi
            payment{
              namapayment
            }
            paymentDeskripsi
            paymentDate
            paymentAmount
            bank{
              kodebank
              namabank
            }
            cabang
            norekening
            namasales
            namaoutlet
            fullName           
          }
        }
      `
      GQL(query, clientID, token).then(resp => {
        const modify = resp.data.listTransBelumKomisi.map(el => {
          return {
            ...el,
            selected: false
          }
        })
        dispatch({ type: "LOADEDDIALOG", data: modify });
      }).catch(err => {
        console.log(err);
        dispatch({ type: "DIALOGERROR" })
      })
    }
    return () => {
      render = false
    };
  }, [state.openDialog])

  const handleDialogOpen = () => dispatch({ type: 'OPENDIALOG' })

  const handleDialogClose = () => dispatch({ type: "CLOSEDIALOG" })

  const handleDateChange = (date) => {
    const newData = {
      ...state.data,
      tanggal:date
    }
    dispatch({type:"UPDATE",data:newData})
  }

  const handleSave = ()=>{
    var yakin = window.confirm("Yakin data sudah benar?");
    if(yakin){
      const selected = state.dataImport.filter(el => el.selected);
      if(selected.length > 0){
        dispatch({ type: "SAVING" });
        var detail = '';
        for(var i=0,j=selected.length;i<j;i++){
          var rekening = '';
          if (selected[i].bank && selected[i].bank.namabank) rekening += selected[i].bank.namabank;
          if (selected[i].cabang) rekening += ` - ${selected[i].cabang}`;
          if (selected[i].norekening) rekening += ` ${selected[i].norekening}`;
          if(i>0) detail += ',';
          detail += `{id_trans:${selected[i].urut},transferke:"${rekening}",noreferensi:"${selected[i].noreferensi}",nilaikomisi:${selected[i].nilaikomisi}}`;
        }
        const query = `
          mutation{
            createKomisi(input:{tanggal:"${state.data.tanggal.format("YYYY-MM-DD")}",detail:[${detail}]})
          }
        `
        GQL(query,clientID,token).then(resp => {
          if(resp.errors) throw resp.errors;
          props.history.push(`/komisidetail/${resp.data.createKomisi}`);
        }).catch(err => {
          console.log(err);
          alert("Server tidak dapat memproses permintaan Anda saat ini. Harap coba beberapa saat lagi")
        })
      }else{
        alert("Mohon pilih komisi yang ditransfer");
      }
    }
  }

  const handleChecked = (element) => {
    element.selected = !element.selected;
    const newData = state.dataImport.map(el => {
      if (el.urut === element.urut) {
        return element;
      }
      return el;
    })
    dispatch({ type: "UPDATEDIALOG", data: newData })
  }

  const onReferensiChanged = (element, val)=>{
    element.noreferensi = val.target.value;
    const newData = state.dataImport.map(el => {
      if (el.urut === element.urut) {
        return element;
      }
      return el;
    })
    dispatch({ type: "UPDATEDIALOG", data: newData })
  }

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <h3 className="border-bottom" style={{ marginTop: 20, paddingBottom: 10 }}>Informasi</h3>
          <TextField
            fullWidth
            label="Nomor Transaksi"
            disabled
            value={state.data.notrans}
            contentEditable={false}
            style={{ marginTop: 10, marginBottom: 10 }}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
            <KeyboardDatePicker
              fullWidth={true}
              margin="normal"
              id="tanggal"
              label="Tanggal"
              format="DD MMM YYYY"
              value={state.data.tanggal}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              required
            />
          </MuiPickersUtilsProvider>
          <TextField
            label="Status"
            disabled
            value={getStatus(state.data.status)}
            style={{ marginTop: 10, marginBottom: 10, marginRight: 20 }}
            contentEditable={false}
          />
          <TextField
            label="User input"
            disabled
            value={state.data.userin}
            style={{ marginTop: 10, marginBottom: 10, marginRight: 20 }}
            contentEditable={false}
          />
          <TextField
            label="Jam Input"
            disabled
            value={state.data.jamin ? moment(state.data.jamin).format("DD MMM YYYY HH:mm:ss a") : ''}
            style={{ marginTop: 10, marginBottom: 10, marginRight: 20 }}
            contentEditable={false}
          />
          <TextField
            label="User Update"
            disabled
            value={state.data.userupt}
            style={{ marginTop: 10, marginBottom: 10, marginRight: 20 }}
            contentEditable={false}
          />
          <TextField
            label="Jam Update"
            disabled
            value={state.data.jamupt ? moment(state.data.jamupt).format("DD MMM YYYY HH:mm:ss a") : ''}
            style={{ marginTop: 10, marginBottom: 10, marginRight: 20 }}
            contentEditable={false}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col xs={12}>
          {
            !props.match.params.notrans && (<Button variant="contained" color="primary" onClick={handleDialogOpen}>Import</Button>)
          }          
          <Paper className="table-responsive" style={{ marginTop: 20 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell style={{ minWidth: 140 }}>Payment</TableCell>
                  <TableCell>Deskripsi</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell style={{ minWidth: 140 }}>Berlaku Hingga</TableCell>
                  <TableCell style={{ minWidth: 140 }}>Pencairan Komisi</TableCell>
                  <TableCell>Keterangan P.Komisi</TableCell>
                  <TableCell style={{ minWidth: 140 }}>User Input</TableCell>
                  <TableCell style={{ minWidth: 140 }}>User Update</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.data.detail && state.data.detail.map((l, idx) => {
                  const el = l['id_trans'];
                  const keteranganKomisi = []
                  if (el.transferKe && el.noreferensi && el.nilaikomisi) {
                    keteranganKomisi.push(<span key={`${idx.toString()}-1`}>{`${el.transferKe} - ${el.noreferensi}`}</span>)
                    keteranganKomisi.push(<br key={`${idx.toString()}-2`} />)
                    keteranganKomisi.push(<strong key={`${idx.toString()}-3`}><NumberFormat value={el.nilaikomisi} displayType={'text'} thousandSeparator={true} /></strong>)
                  }
                  return (
                    <TableRow key={idx.toString()}>
                      <TableCell>{idx + 1}</TableCell>
                      <TableCell>{el.payment.namapayment}<br />{moment(el.paymentDate).format("DD MMM YYYY")}</TableCell>
                      <TableCell>{el.paymentDeskripsi}</TableCell>
                      <TableCell align="right"><NumberFormat value={el.paymentAmount} displayType={'text'} thousandSeparator={true} /></TableCell>
                      <TableCell>{moment(el.expiryDate).format("DD-MMM-YYYY")}</TableCell>
                      <TableCell>{el.tanggalPencairanKomisi ? `${moment(el.tanggalPencairanKomisi).format("DD MMM YYYY")}` : `Belum Cair`}</TableCell>
                      <TableCell align="center">{(el.transferKe && el.noreferensi && el.nilaikomisi) ? keteranganKomisi : `Belum Cair`}</TableCell>
                      <TableCell align="center">{el.userin}<br />{moment(el.jamin).format("DD MMM YYYY hh:mm:ss a")}</TableCell>
                      <TableCell align="center">{el.userupt}<br />{moment(el.jamupt).format("DD MMM YYYY hh:mm:ss a")}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Paper>
        </Col>
      </Row>
      <Dialog fullScreen open={state.openDialog} onClose={handleDialogClose}>
        <AppBar style={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleDialogClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" style={{ flex: 1, marginLeft: 10 }}>
              Import Transaksi
            </Typography>
            <Button color="inherit" onClick={handleSave}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <Paper className="table-responsive">

        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Selection</TableCell>
              <TableCell align="center">Outlet</TableCell>
              <TableCell style={{minWidth:150}} align="center">Payment</TableCell>
              <TableCell align="center">Amount</TableCell>
              <TableCell align="center">Komisi</TableCell>
              <TableCell style={{minWidth:200}} align="center">Bank</TableCell>
              <TableCell style={{minWidth:200}} align="center">No Ref Transfer</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.dataImport.length > 0 && state.dataImport.map((el, index) => {
              const noreferensi = el.noreferensi || '';
              var rekening = '';
              if (el.bank && el.bank.namabank) rekening += el.bank.namabank;
              if (el.cabang) rekening += ` - ${el.cabang}`;
              if (el.norekening) rekening += ` ${el.norekening}`;
              rekening += ` a/n ${el.namasales}`
              return (
                <TableRow key={index.toString()}>
                  <TableCell align="center">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={el.selected}
                          onChange={handleChecked.bind(this, el)}
                          inputProps={{
                            'aria-label': 'primary checkbox',
                          }}
                          color="primary"
                        />}
                      label="Pilih"
                      className="label-black"
                    />
                  </TableCell>
                  <TableCell>{el.namaoutlet}</TableCell>
                  <TableCell align="center">
                    {moment(el.paymentDate).format("DD-MMM-YYYY")}<br />
                    {el.payment.namapayment}<br />
                    {el.paymentDeskripsi}
                  </TableCell>
                  <TableCell align="right"><NumberFormat value={el.paymentAmount} displayType={'text'} thousandSeparator={true} /></TableCell>
                  <TableCell align="right"><NumberFormat value={el.nilaikomisi} displayType={'text'} thousandSeparator={true} /></TableCell>
                  <TableCell>{rekening}</TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      value={noreferensi}
                      onChange={(val) => onReferensiChanged(el,val)}
                      className="input-black"
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        </Paper>
      </Dialog>
    </Container>
  )
}

export default KomisiDetail;