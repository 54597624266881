import React, { useEffect, useState, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import GQL from '../../GQL';
import AuthContext from '../../context/auth-context'
import { FaChevronLeft } from 'react-icons/fa';
import DateFnsUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment-timezone'
import { TextField, Button } from '@material-ui/core';
const Contract = (props) => {

  const { clientID, token } = useContext(AuthContext)
  const [namaoutlet, setNamaoutlet] = useState(null)
  const { kodeoutlet } = props.match.params;
  const [selectedDate, setSelectedDate] = useState(moment());
  const [harga, setHarga] = useState(0);
  const [komisisales, setKomisisales] = useState(0);

  useEffect(() => {
    var render = true;
    if (render && kodeoutlet) {
      const query = `
      query{
        outlet(kodeoutlet:"${kodeoutlet}"){
          namaoutlet
        }
      }
    `
      GQL(query, clientID, token).then(resp => {
        if (resp.errors) throw resp.errors;
        setNamaoutlet(resp.data.outlet.namaoutlet);
      }).catch(err => {
        console.log(err);
        alert("Server tidak dapat memenuhi permintaan Anda saat ini. Harap coba beberapa saat lagi")
      })

    }
    return () => {
      render = false;
    };
  }, [kodeoutlet])

  const goBack = () => {
    props.history.push(`/outlet/${kodeoutlet}`)
  }

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  const handleHargaChange = (event) => {
    setHarga(+event.target.value)
  }

  const handleKomisiChange = (event) => {
    setKomisisales(+event.target.value)
  }

  const simpanKontrak = () => {
    var yakin = window.confirm(`Yakin akan menyimpan kontrak dengan harga ${harga} dan komisi sales ${komisisales} berlaku per tanggal ${selectedDate.format("DD MMM YYYY")}?`);
    if (yakin) {
      if(komisisales > (harga * 0.3)){
        var yakinsangat = window.confirm(`Komisi sales melebihi dari batas wajar. Batas wajar: ${harga * 0.3} sedangkan komisi sebesar ${komisisales}. Anda benar yakin?`);
        if(!yakinsangat) return;
      }
      const query = `
      mutation{
        createContract(input:{kodeoutlet:"${kodeoutlet}",tanggal:"${selectedDate}",harga:${harga},komisisales:${komisisales}})
      }
    `
      GQL(query, clientID, token).then(resp => {
        if (resp.errors) throw resp.errors;
        if(resp.data.createContract){
          alert("Kontrak berhasil disimpan");
          props.history.push(`/outlet/${kodeoutlet}`)
        }else{
          alert("Server tidak dapat menyimpan permohonan Anda. Harap coba beberapa saat lagi");
        }
      }).catch(err => {
        console.log(err);
        alert("Server tidak dapat memenuhi permintaan Anda saat ini. Harap coba beberapa saat lagi")
      })
    }
  }

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <h3 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={goBack.bind(this)} className="btnBack"/> Kontrak Baru untuk  {namaoutlet}</h3>
          <MuiPickersUtilsProvider utils={DateFnsUtils} style={{marginTop:20,marginBottom:20}}>
            <KeyboardDatePicker
              fullWidth={true}
              margin="normal"
              id="tanggal"
              label="Tanggal"
              format="DD MMM YYYY"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              required
            />
          </MuiPickersUtilsProvider>
          <TextField
            id="harga"
            type="number"
            label="Harga Kontrak"
            value={harga}
            fullWidth={true}
            required
            onChange={handleHargaChange}
            style={{ marginTop: 20, marginBottom: 20 }}
          />
          <TextField
            id="komisisales"
            type="number"
            label="Nominal Komisi Sales"
            value={komisisales}
            fullWidth={true}
            required
            onChange={handleKomisiChange}
            style={{ marginTop: 20, marginBottom: 20 }}
          />
          <div className="text-center">
            <Button variant="contained" color="primary" style={{ marginTop: 20, marginBottom: 20 }} onClick={simpanKontrak}>Simpan</Button>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
export default Contract;