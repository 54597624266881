import React, { useReducer, useEffect, useContext } from 'react'
import AuthContext from '../../context/auth-context'
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { FaChevronLeft } from 'react-icons/fa';
import { TextField, Button } from '@material-ui/core';
import moment from 'moment-timezone'
import GQL from '../../GQL';
const initialValue = {
  isLoading: false,
  isSaving: false,
  data: {
    id: undefined,
    namapayment: undefined,
    status:1,
    userin:undefined,
    userupt:undefined,
    jamin:undefined,
    jamupt:undefined
  },
  isReload:true
};

const reducer =(state,action)=>{
  switch(action.type){
    case "LOADING":
      return {
        ...initialValue,
        isLoading:true,
        error:null
      }
    case "LOADED":{
      return {
        ...state,
        isLoading:false,
        data: action.data,
        isReload:false
      }
    }
    case "SAVING":
      return {
        ...state,
        isSaving:true,
        error:null
      }
    case "SAVED":
      return {
        ...state,
        isSaving:false,
        error:null,
        isReload: !action.isNew
      }
    case "ERROR":
      return{
        ...state,
        isLoading:false,
        isSaving:false,
        error: action.error,
        isReload: false
      }
    case "UPDATE":
      return {
        ...state,
        data: action.data
      }
  }
}

const PaymentDetail = (props) => {

  const [state, dispatch] = useReducer(reducer, initialValue)
  const {clientID, token} = useContext(AuthContext)

  useEffect(() => {
    var render = true
    const {id} = props.match.params;
    if(render && id && state.isReload){
      render && dispatch({type:"LOADING"})
      const query = `
        query{
          outletPayment(id:${id}){
            id
            namapayment
            userin
            userupt
            jamin
            jamupt
            status
          }
        }
      `
      GQL(query,clientID,token).then(resp =>{
        if(resp.errors) throw resp.errors;
        dispatch({type:"LOADED",data:resp.data.outletPayment})
      }).catch(err => {
        console.log(err);
        alert("Server tidak dapat memenuhi permintaan Anda saat ini. Silakan coba beberapa saat lagi");
        dispatch({type:"ERROR",error:err});
      })
    }
    return () => {
      render = false
    };
  }, [props.match.params.id,state.isReload])

  const goBack = ()=>{
    props.history.push('/payment')
  }

  const {id, namapayment, userin, userupt, jamin, jamupt} = state.data;

  const handleNamapayment = (ev)=>{
    const newData = {
      ...state.data,
      namapayment: ev.target.value
    }
    dispatch({type:"UPDATE",data:newData});
  }

  const simpanData = ()=>{
    const {id} = props.match.params;
    if(id){
      const myHeader = new Headers();
      myHeader.append("Accept", "application/json");
      myHeader.append("Content-Type", "application/json");
      myHeader.append("X-API-CLIENT-ID", clientID);
      myHeader.append("X-API-TOKEN", token);
      const myInit = {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify({ kodepayment: state.data.id, namapayment: state.data.namapayment })
      }
      const myRequest = new Request(`${process.env.REACT_APP_URL}/payment/update`, myInit);
      fetch(myRequest).then(res => res.json()).then(resp => {
        const { info, success } = resp;
        if (success) {
          dispatch({ type: "SAVED", isNew:false });
          alert("Payment berhasil diupdate");
        } else {
          throw new Error(info);
        }
      }).catch(err => {
        console.log(err);
        dispatch({ type: "ERROR", error: err });
      })
    }else{
      const myHeader = new Headers();
      myHeader.append("Accept", "application/json");
      myHeader.append("Content-Type", "application/json");
      myHeader.append("X-API-CLIENT-ID", clientID);
      myHeader.append("X-API-TOKEN", token);
      const myInit = {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify({ namapayment: state.data.namapayment })
      }
      const myRequest = new Request(`${process.env.REACT_APP_URL}/payment/simpan`, myInit);
      fetch(myRequest).then(res => res.json()).then(resp => {
        const { info, success, kodepayment } = resp;
        if (success) {
          dispatch({ type: "SAVED", isNew: true });
          alert("Payment berhasil disimpan");
          props.history.push(`/paymentdetail/${kodepayment}`);
        } else {
          throw new Error(info);
        }
      }).catch(err => {
        console.log(err);
        dispatch({ type: "ERROR", error: err });
      })
    }
  }

  if (state.isLoading || state.isSaving) return (<Spinner animation="border" role="status" className="spinner">
    <span className="sr-only">Loading...</span>
  </Spinner>)

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <h3 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft className="btnBack" onClick={goBack.bind(this)}/> Payment Form</h3>
          <TextField
            id="id"
            fullWidth
            label="Kode Payment (System Generated)"
            value={id}
            style={{margin:20}}
           />
          <TextField
            id="namapayment"
            fullWidth
            label="Nama Payment"
            value={namapayment}
            type="text"
            style={{margin:20}}
            onChange={handleNamapayment}
          />
          <TextField
            id="User Input"
            
            label="User Input"
            disabled
            value={userin}
            style={{margin:20}}
          />
          <TextField
            id="jamin"
            
            label="Jam Input"
            disabled
            value={jamin ? moment(jamin).format("DD MMM YYYY hh:mm:ss a"): undefined}
            style={{margin:20}}
          />
          <TextField
            id="userupt"
            
            label="User Update"
            disabled
            value={userupt}
            style={{margin:20}}
          />
          <TextField
            id="jamupt"
            
            label="Jam Update"
            disabled
            value={jamupt ? moment(jamupt).format("DD MMM YYYY hh:mm:ss a") : undefined}
            style={{margin:20}}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-center">
            <Button variant="contained" color="primary" style={{marginTop:20,marginBottom:20}} onClick={simpanData}>{props.match.params.id ? "UPDATE": "SIMPAN"}</Button>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default PaymentDetail;