import React, { useState } from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap'
import { NavLink, Link } from 'react-router-dom'

const MyNavbar = (props) => {

  const [navExpanded, setNavExpanded] = useState(false)
  const Logo = () => {
    if (process.env.REACT_APP_COMPANY == "MonkeyPOS") {
      return (
        <React.Fragment>
          <img alt="logo" src={`/icon/${process.env.REACT_APP_COMPANY}/logo.png`} width="50" height="50" />  {'Monkey POS'}
        </React.Fragment>
      )
    } else if (process.env.REACT_APP_COMPANY == "Ipos") {
      return (
        <React.Fragment>
          <img alt="logo" src={`/icon/${process.env.REACT_APP_COMPANY}/logo.png`} width="100" height="40" />
        </React.Fragment>
      )
    }
  }
  return (
    <Navbar bg="dark" expand="lg" className="fixed-top" onToggle={setNavExpanded.bind(true)} expanded={navExpanded}>
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <Logo />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto" onClick={setNavExpanded.bind(this, false)}>
            <NavLink className="nav-link" to="/">Home</NavLink>
            {props.isLogin &&
              <React.Fragment>
                <NavLink className="nav-link" to="/outlet/1/5">Outlet</NavLink>
                <NavLink className="nav-link" to="/payment">Payment</NavLink>
                <NavLink className="nav-link" to="/sales">Sales</NavLink>
                <NavLink className="nav-link" to="/komisi">Komisi</NavLink>
                <NavLink className="nav-link" to="/user">User</NavLink>
                <NavLink className="nav-link" to="/customer">Customer</NavLink>
                <NavLink className="nav-link" to="/userlaporan/1/5">User Laporan</NavLink>
                <NavLink className="nav-link" to="/voucher/1/5">Voucher</NavLink>
                <Nav.Link onClick={props.onLogout.bind(this)}>Log Out</Nav.Link>
              </React.Fragment>
              ||
              <NavLink className="nav-link" to="/login">Login</NavLink>
            }
          </Nav>
        </Navbar.Collapse>
      </Container>

    </Navbar>
  )
}

export default MyNavbar;