import React, { useReducer, useEffect, useContext } from 'react'
import AuthContext from '../../context/auth-context'
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { FaChevronLeft } from 'react-icons/fa';
import { TextField, Button } from '@material-ui/core';
import moment from 'moment-timezone'
import GQL from '../../GQL';
const initialValue = {
  isLoading: false,
  isSaving: false,
  data: {
    username: undefined,
    password: undefined,
    userin:undefined,
    userupt:undefined,
    jamin:undefined,
    jamupt:undefined
  },
  isReload:true
};

const reducer =(state,action)=>{
  switch(action.type){
    case "LOADING":
      return {
        ...initialValue,
        isLoading:true,
        error:null
      }
    case "LOADED":{
      return {
        ...state,
        isLoading:false,
        data: action.data,
        isReload:false
      }
    }
    case "SAVING":
      return {
        ...state,
        isSaving:true,
        error:null
      }
    case "SAVED":
      return {
        ...state,
        isSaving:false,
        error:null,
        isReload: !action.isNew
      }
    case "ERROR":
      return{
        ...state,
        isLoading:false,
        isSaving:false,
        error: action.error,
        isReload: false
      }
    case "UPDATE":
      return {
        ...state,
        data: action.data
      }
  }
}

const UserDetail = (props) => {

  const [state, dispatch] = useReducer(reducer, initialValue)
  const {clientID, token} = useContext(AuthContext)

  useEffect(() => {
    var render = true
    const {username} = props.match.params;
    if(render && username && state.isReload){
      render && dispatch({type:"LOADING"})
      const myHeader = new Headers();
      myHeader.append("Accept", "application/json");
      myHeader.append("Content-Type", "application/json");
      myHeader.append("X-API-CLIENT-ID", clientID);
      myHeader.append("X-API-TOKEN", token);
      const myInit = {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify({ username })
      }
      const myRequest = new Request(`${process.env.REACT_APP_URL}/users/getByUser`, myInit);
      fetch(myRequest).then(res => res.json()).then(resp => {
        const { info, success, data } = resp;
        if (success) {
          dispatch({ type: "LOADED", data: data });
        } else {
          throw new Error(info);
        }
      }).catch(err => {
        console.log(err);
        dispatch({ type: "ERROR", error: err });
      })
    }
    return () => {
      render = false
    };
  }, [props.match.params.username,state.isReload])

  const goBack = ()=>{
    props.history.push('/user')
  }

  const {username, password, userin, userupt, jamin, jamupt} = state.data;

  const handleUsername = (ev) => {
    const newData = {
      ...state.data,
      username: ev.target.value
    }
    dispatch({ type: "UPDATE", data: newData });
  }
  const handleNamapayment = (ev)=>{
    const newData = {
      ...state.data,
      password: ev.target.value
    }
    dispatch({type:"UPDATE",data:newData});
  }

  const simpanData = ()=>{
    const {username} = props.match.params;
    if(username){
      const myHeader = new Headers();
      myHeader.append("Accept", "application/json");
      myHeader.append("Content-Type", "application/json");
      myHeader.append("X-API-CLIENT-ID", clientID);
      myHeader.append("X-API-TOKEN", token);
      const myInit = {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify({ username: state.data.username, password: state.data.password })
      }
      const myRequest = new Request(`${process.env.REACT_APP_URL}/users/changePassword`, myInit);
      fetch(myRequest).then(res => res.json()).then(resp => {
        const { info, success } = resp;
        if (success) {
          dispatch({ type: "SAVED", isNew:false });
          alert("Password berhasil diupdate");
        } else {
          throw new Error(info);
        }
      }).catch(err => {
        alert(err.message);
        console.log(err);
        dispatch({ type: "ERROR", error: err });
      })
    }else{
      const myHeader = new Headers();
      myHeader.append("Accept", "application/json");
      myHeader.append("Content-Type", "application/json");
      myHeader.append("X-API-CLIENT-ID", clientID);
      myHeader.append("X-API-TOKEN", token);
      const myInit = {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify({ username: state.data.username, password: state.data.password })
      }
      const myRequest = new Request(`${process.env.REACT_APP_URL}/users/createUser`, myInit);
      fetch(myRequest).then(res => res.json()).then(resp => {
        const { info, success } = resp;
        if (success) {
          dispatch({ type: "SAVED", isNew: true });
          alert("User berhasil dibuat");
          props.history.push(`/userdetail/${state.data.username}`);
        } else {
          throw new Error(info);
        }
      }).catch(err => {
        alert(err.message);
        console.log(err);
        dispatch({ type: "ERROR", error: err });
      })
    }
  }

  if (state.isLoading || state.isSaving) return (<Spinner animation="border" role="status" className="spinner">
    <span className="sr-only">Loading...</span>
  </Spinner>)

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <h3 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={goBack.bind(this)} className="btnBack"/> User Form</h3>
          <TextField
            id="username"
            fullWidth
            label="Username"
            value={username}
            style={{margin:20}}
            disabled={props.match.params.username}
            onChange={handleUsername}
           />
          <TextField
            id="password"
            fullWidth
            label="Password"
            value={password}
            type="password"
            style={{margin:20}}
            onChange={handleNamapayment}
          />
          <TextField
            id="User Input"
            
            label="User Input"
            disabled
            value={userin}
            style={{margin:20}}
          />
          <TextField
            id="jamin"
            
            label="Jam Input"
            disabled
            value={jamin ? moment(jamin).format("DD MMM YYYY hh:mm:ss a"): undefined}
            style={{margin:20}}
          />
          <TextField
            id="userupt"
            
            label="User Update"
            disabled
            value={userupt}
            style={{margin:20}}
          />
          <TextField
            id="jamupt"
            
            label="Jam Update"
            disabled
            value={jamupt ? moment(jamupt).format("DD MMM YYYY hh:mm:ss a") : undefined}
            style={{margin:20}}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-center">
            <Button variant="contained" color="primary" style={{marginTop:20,marginBottom:20}} onClick={simpanData}>{props.match.params.username ? "UPDATE": "SIMPAN"}</Button>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default UserDetail;