import React, {useReducer, useEffect, useContext} from 'react'
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, Button } from '@material-ui/core';
import AuthContext from '../../context/auth-context';
import GQL from '../../GQL';
import './Sales.css';

const initialValue = {
  isLoading:false,
  isSaving:false,
  isReload:true,
  data:[]
}

const reducer = (state,action)=>{
  switch(action.type){
    case "LOADING":
      return {
        ...initialValue,
        isLoading:true,
      }
    case "LOADED":
      return {
        ...state,
        isLoading:false,
        data: action.data
      }
    case "SAVING":
      return {
        ...state,
        isSaving:true
      }
    case "SAVED":
      return {
        ...state,
        isSaving:false,
        isReload:true
      }
    case "ERROR":
      return {
        ...state,
        isLoading:false
      }
    default: return state;
  }
}

const obfuscateEmail = (email)=>{
  var em = email.split("@");
  var name = [em.slice(0,em.length -1),'@'].join();
  var len = Math.floor(name.length/2)
  return name.substr(0,len) + '*'.repeat(len) + "@" + em[em.length-1];
}

const Sales = (props)=>{

  const [state, dispatch] = useReducer(reducer, initialValue)
  const {clientID,token} = useContext(AuthContext)

  useEffect(() => {
    var render = true
    dispatch({type:"LOADING"})
    const query = `
      query{
        listSales{
          namasales
          email
          status
        }
      }
    `
    GQL(query,clientID,token).then(resp => {
      if(resp.errors) throw resp.errors;
      dispatch({type:"LOADED",data:resp.data.listSales})
    }).catch(err => {
      alert("Server tidak dapat memenuhi permintaan Anda saat ini. Silakan coba beberapa saat lagi")
      console.log(err);
      dispatch({type:"ERROR"})
    })
    return () => {
      render = false;
    };
  }, [])

  const viewDetail = (email)=> props.history.push(`/salesDetail/${email}`)

  const addSales = ()=> props.history.push(`/salesdetail`);

  if (state.isLoading) return (<Spinner animation="border" role="status" className="spinner">
    <span className="sr-only">Loading...</span>
  </Spinner>)
  
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <h3 style={{marginTop:20,paddingBottom:10}} className="border-bottom">Sales Management</h3>
          <Button variant="contained" color="primary" onClick={addSales}>Tambah Sales</Button>
          <Paper className="table-responsive" style={{marginTop:20}}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Nama Sales</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.data.length > 0 && (
                  state.data.map((el,index)=>(
                    <TableRow key={index.toString()} className={el.status == 8 ? 'suspend' : ''}>
                      <TableCell>{index+1}</TableCell>
                      <TableCell>{el.namasales}</TableCell>
                      <TableCell>{obfuscateEmail(el.email)}</TableCell>
                      <TableCell>
                        <Button variant="contained" color="primary" onClick={viewDetail.bind(this,el.email)}>Detail</Button>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </Paper>
        </Col>
      </Row>
    </Container>
  )
}

export default Sales