import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import validate from 'validate.js'
import mControl from './Voucher.ctrl'
import DateFnsUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment-timezone'


const Modal = (props) => {
    const dataSelect = props.dataSelect
    const [isUpdate, setIsUpdate] = useState(false)
    const [form, setForm] = useState({
        namavoucher: "",
        tanggalMulai: moment(),
        tanggalSelesai: moment(),
        jumlahVoucher: 1,
        freeTrialDay: 0,
        kodevoucher: ''
    })
    useEffect(() => {
        if (dataSelect.kodevoucher) {
            setIsUpdate(true)
            setForm(dataSelect)
        }
        return () => {

        }
    }, [dataSelect])

    const handleClose = () => {
        props.setOpen(false);
    };

    const validateForm = () => {
        const { namavoucher, jumlahVoucher } = form;
        const promForm = new Promise((resolve, reject) => {
            const constraint = {
                namavoucher: {
                    length: {
                        minimum: 3,
                        message: "Nama Voucher minimal terdiri dari 3 huruf"
                    },
                },
                jumlahVoucher: {
                    numericality: {
                        greaterThan: 0,
                        message: "Jumlah Voucher minimal 1"
                    },
                },
            }
            const validator = validate({ namavoucher, jumlahVoucher }, constraint, { fullMessages: false });
            if (validator === undefined) {
                resolve();
            } else {
                reject(validator);
            }
        })
        return Promise.all([promForm]);
    }

    const simpan = () => {
        validateForm().then(() => {
            const { namavoucher, tanggalMulai, tanggalSelesai, jumlahVoucher, freeTrialDay, kodevoucher } = form;
            mControl.saveVoucher(props.clientID, props.token, namavoucher, moment(tanggalMulai).format("YYYY-MM-DD"), moment(tanggalSelesai).format("YYYY-MM-DD"), jumlahVoucher, freeTrialDay, kodevoucher).then(resp => {
                alert("Data Berhasil Disimpan")
                handleClose()
                props.setIsLoading(true)
            }).catch(err => {
                if (err.length > 0) {
                    alert(err[0].message)
                } else if (typeof err) {
                    alert(err.message)
                } else {
                    console.log(err);
                }
            })
        }).catch(err => {
            console.log(err)
            const keys = Object.keys(err);
            var errorMessage = "";
            for (const key of keys) {
                errorMessage += err[key] + "\n";
            }
            alert(errorMessage);
        })
    }

    const update = () => {
        if(form.voucherTerpakai > form.jumlahVoucher) return alert("Jumlah Voucher harus melebihi voucher yang terpakai")
        validateForm().then(() => {
            const { namavoucher, tanggalMulai, tanggalSelesai, jumlahVoucher, freeTrialDay, kodevoucher } = form;
            mControl.updateVoucher(props.clientID, props.token, namavoucher, moment(tanggalMulai).format("YYYY-MM-DD"), moment(tanggalSelesai).format("YYYY-MM-DD"), jumlahVoucher, freeTrialDay, kodevoucher).then(resp => {
                alert("Data Berhasil Diupdate")
                handleClose()
                props.setIsLoading(true)
            }).catch(err => {
                if (err.length > 0) {
                    alert(err[0].message)
                } else if (typeof err) {
                    alert(err.message)
                } else {
                    console.log(err);
                }
            })
        }).catch(err => {
            console.log(err)
            const keys = Object.keys(err);
            var errorMessage = "";
            for (const key of keys) {
                errorMessage += err[key] + "\n";
            }
            alert(errorMessage);
        })

    }
    const handleChange = (ev) => {
        var value = ev.target.value;
        const key = ev.target.id || ev.target.name;
        var newForm = { ...form }
        newForm[key] = value;
        setForm(newForm);
    }
    return (
        <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{isUpdate ? "Edit" : "Add"} Voucher {isUpdate && <small><i>(Terpakai {form.voucherTerpakai})</i></small>}</DialogTitle>
            <DialogContent>
                <TextField
                    margin="dense"
                    id="kodevoucher"
                    label="Kode Voucher (Boleh Dikosongkan)"
                    fullWidth
                    multiline={true}
                    value={form.kodevoucher}
                    onChange={handleChange}
                    disabled={isUpdate ? true : false} />
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="namavoucher"
                    label="Nama Voucher"
                    fullWidth
                    multiline={true}
                    value={form.namavoucher}
                    onChange={handleChange} />
                <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                    <KeyboardDatePicker
                        fullWidth={true}
                        margin="normal"
                        id="tanggalMulai"
                        label="Tanggal Mulai"
                        format="DD-MM-YYYY"
                        value={form.tanggalMulai}
                        onChange={(date) => setForm({ ...form, tanggalMulai: date })}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        required
                    />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ marginTop: 20, marginBottom: 20 }}>
                    <KeyboardDatePicker
                        fullWidth={true}
                        margin="normal"
                        id="tanggalSelesai"
                        label="Tanggal Selesai"
                        format="DD-MM-YYYY"
                        value={form.tanggalSelesai}
                        onChange={(date) => setForm({ ...form, tanggalSelesai: date })}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        required
                    />
                </MuiPickersUtilsProvider>
                <TextField
                    required
                    margin="dense"
                    id="jumlahVoucher"
                    label="Jumlah Voucher"
                    type="number"
                    fullWidth
                    multiline={true}
                    value={form.jumlahVoucher}
                    InputProps={{ inputProps: { min: 0, max: 10 } }}
                    onChange={handleChange} />
                <TextField
                    required
                    margin="dense"
                    id="freeTrialDay"
                    label="Free Trial Day"
                    type="number"
                    fullWidth
                    multiline={true}
                    value={form.freeTrialDay}
                    onChange={handleChange} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="">Cancel</Button>
                {isUpdate ? (
                    <Button onClick={update} color="secondary">Update</Button>
                ) : (
                        <Button onClick={simpan} color="secondary">Simpan</Button>
                    )}
            </DialogActions>
        </Dialog>
    )
}
export default Modal;
