import React, { useEffect, useReducer, useContext, useState } from 'react'
// import { RecyclerListView, DataProvider, LayoutProvider } from 'recyclerlistview/web';
import { Container, Row, Col } from 'react-bootstrap';
import AuthContext from '../../context/auth-context';
import { Button, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Paper, InputBase } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search'
import useStyles from '../../Desain/useStylesTabel'
import Skeleton from 'react-loading-skeleton';
import mControl from './UserLaporanOutlet.ctrl'
import ModalForm from './Modal.UserLaporanOutlet'
import { FaChevronLeft } from 'react-icons/fa';


const loadingRow = [0, 1, 2, 3, 4];

const desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

const getSorting = (order, orderBy) => {
    return order === "desc"
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}
const headRows = [
    { id: "kodeoutlet", label: "Kodeoutlet" },
    { id: "namaoutlet", label: "Nama Outlet" },

];

const UserLaporan = (props) => {
    let defaultPage = parseInt(props.match.params.page, 10) - 1;
    let defaultrowsPerPage = parseInt(props.match.params.rowsPerPage, 10);
    let email = props.match.params.email
    const { clientID, token } = useContext(AuthContext);
    const classes = useStyles();
    const [order, setOrder] = React.useState("desc");
    const [orderBy, setOrderBy] = React.useState("idcust");
    const [page, setPage] = React.useState(defaultPage);
    const [rowsPerPage, setRowsPerPage] = React.useState(defaultrowsPerPage);
    const [search, setSearch] = useState(null)
    const [dataOri, setDataOri] = useState([])
    const [listData, setListData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isOpenForm, setIsOpenForm] = useState(false)
    const [listOutlet, setListOutlet] = useState([])


    useEffect(() => {
        mControl.loadUserOutlet(clientID, token, email).then(resp => {
            setListData(resp)
            setDataOri(resp)
        }).catch(err => {
            console.error(err);
        }).finally(() => setIsLoading(false))
        return () => {

        };
    }, [isLoading, clientID, token, email])
    useEffect(() => {
        mControl.loadOutlet(clientID, token).then(resp => {
            setListOutlet(resp)
        }).catch(err => {
            console.error(err);
        })
        return () => {
            
        }
    }, [clientID, token])

    const addOutlet = () => {
        setIsOpenForm(true)
    }


    const handleDelete = data => {
        const konfirm = window.confirm(`Yakin akan menghapus ${data.namaoutlet} dari user ${email}?`);
        if (konfirm) {
            mControl.hapusUserOutlet(clientID, token, email, data.kodeoutlet).then(resp => {
                alert(`Outlet berhasil dihapus dari user ${email}`)
                setIsLoading(true)
            }).catch(err => {
                if (err.length > 0) {
                    alert(err[0].message)
                } else if (typeof err) {
                    alert(err.message)
                } else {
                    console.log(err);
                }
            })
        }
    }

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }
    const EnhancedTableHead = (props) => {
        const {
            classes,
            order,
            orderBy,
            onRequestSort
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };
        return (
            <TableHead>
                <TableRow>
                    {headRows.map(row => (
                        <TableCell key={row.id}>
                            <TableSortLabel
                                active={orderBy === row.id}
                                direction={order}
                                onClick={createSortHandler(row.id)}
                            >
                                {row.label}
                                {orderBy === row.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                    <TableCell style={{ width: ' 25px' }}>Action</TableCell>
                </TableRow>
            </TableHead>
        );
    }
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, listData.length - page * rowsPerPage);
    const searchHandler = (val) => {
        setSearch(val.target.value)
    }
    const formSubmitHandler = () => {
        setPage(0)
        setRowsPerPage(5)
        if (search) {
            const results = dataOri.filter(el => {
                return el.email.toUpperCase().match(search.toUpperCase()) || el.namacustomer.toUpperCase().match(search.toUpperCase()) || el.nohp.toUpperCase() === search.toUpperCase();
            })
            setListData(results)
        } else {
            setListData(dataOri)
        }
    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            formSubmitHandler()
        }
    }
    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <h4 style={{ marginTop: 20, paddingBottom: 10 }} className="border-bottom"><FaChevronLeft onClick={() =>  props.history.push(`/userlaporan/${defaultPage + 1}/${defaultrowsPerPage}`)} className="btnBack" />Akses Outlet {email}</h4>
                    <Button variant="contained" color="primary" onClick={addOutlet}>Add Akses</Button>
                    <Paper className="table-responsive" style={{ marginTop: 10 }}>
                        <div className="btnTable">
                            <Row>
                                <Col>
                                    <div className={classes.search}>
                                        <div className={classes.searchIcon}>
                                            <SearchIcon />
                                        </div>
                                        <InputBase onChange={searchHandler} onKeyPress={handleKeyPress}
                                            placeholder="Search…"
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputInput,
                                            }}
                                            inputProps={{ 'aria-label': 'search' }}
                                        />
                                        <Button style={{ float: 'right' }} onClick={formSubmitHandler} variant="contained" color="primary">Cari</Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className={classes.tableWrapper}>
                            <Table
                                className="table"
                                aria-labelledby="tableTitle"
                                size={"small"}
                            >
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={listData.length}
                                />
                                {isLoading && loadingRow.map(el => (
                                    <TableRow
                                        key={el.toString()}
                                        style={{ height: 50 }}
                                    >
                                        <TableCell>
                                            <Skeleton />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton />
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {stableSort(listData, getSorting(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                key={index}
                                                role="checkbox"
                                            >
                                                <TableCell>
                                                    {row.kodeoutlet}
                                                </TableCell>
                                                <TableCell>
                                                    {row.namaoutlet}
                                                </TableCell>
                                                <TableCell>
                                                    <Button title="Hapus" variant="contained" style={{ backgroundColor: 'red', marginRight: 10, color: "#fff", width: '130px' }} onClick={handleDelete.bind(this, row)}><DeleteIcon /> Hapus</Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 49 * emptyRows }}>
                                        <TableCell colSpan={7} />
                                    </TableRow>
                                )}
                                <TableBody>

                                </TableBody>
                            </Table>
                        </div>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            component="div"
                            count={listData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                "aria-label": "previous page"
                            }}
                            nextIconButtonProps={{
                                "aria-label": "next page"
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Col>
            </Row>
            {isOpenForm && <ModalForm open={isOpenForm} setOpen={setIsOpenForm} email={email} clientID={clientID} token={token} setIsLoading={setIsLoading} listOutlet={listOutlet}/>}
        </Container>
    )
}

export default UserLaporan;